import React from 'react'
import TextField from '../common/TextField/TextField'

const CompanySelectorFilter = props => {
    const { onChange, className } = props
    
    return (
        <div className={ `${className || ''} CompanySelectorFilter` }>
            <TextField
                {...props}
                label="Digite o nome da empresa"
                input={ {
                onChange 
             } } />
        </div>
    )
}

export default CompanySelectorFilter