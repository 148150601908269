import React from 'react'

import ContractsTableForm from './contractsTableForm'
import PageSection from '../layout/PageSection/PageSection'

const Contracts = props => {
    return (
        <section className="Contracts">
            <PageSection
                containerFluid
                title="Contratos"
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <ContractsTableForm />
                    </div>
                </div>
            </PageSection>
        </section>
    )
}



export default Contracts