import React, { useCallback } from 'react'
import './FileInput.css'

import { useDropzone } from 'react-dropzone'

import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Feedback from '../Feedback/Feedback'

const FileInput = ({ input, accept, label, meta: { error } }) => {
    const handleFocus = useCallback(e => {
        e.target.classList.add('has-focus')
    }, [])
    const handleBlur = useCallback(e => {
        e.target.classList.remove('has-focus')
    }, [])

    const onDrop = useCallback(filesToUpload => {
        return input.onChange(filesToUpload)
    }, [input])

    const onChange = useCallback(filesToUpload => {
        return input.onChange(filesToUpload)
    }, [input])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div className="FileInput" {...getRootProps()}>
            <label className={ error ? "isInvalid" : '' } htmlFor={ input.name }>
                <FontAwesomeIcon className="me-2" icon={faUpload} color="#fff" />
                <span className="inputLabel">{ label }</span>
            </label>
            { error && <Feedback type="invalid">{ error }</Feedback> }
            <input
                { ...getInputProps() }
                onChange={e => onChange(e.target.files)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                accept={accept} />
        </div>
    )
}

export default FileInput