import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'
import CellSelect from '../../common/Table/CellSelect'

import { DATA_KEY_TYPE } from '../documentsConsts'
import documentTypes from '../documentTypes'
import { required } from '../../form/validators'

const DocumentType = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    const renderOption = useCallback((option) => (
        <option key={option} value={option}>{ documentTypes[option] }</option>
    ), [])

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={documentTypes[getValue()] || ''}
            renderField={() => (
                <Field
                    name={DATA_KEY_TYPE}
                    component={CellSelect}
                    defaultValue={getValue()}
                    validate={required}>
                    <option value="">Selecione o tipo</option>
                    { Object.keys(documentTypes).map(renderOption) }
                </Field>
            )} />
    )
}

DocumentType.propTypes = {
    editableRowIndex: PropTypes.number
}

export default DocumentType