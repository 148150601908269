import React, { useCallback } from 'react'
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getSortedRowModel
} from '@tanstack/react-table'

import './Table.css'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

const Table = props => {
    const {
        columns,
        data,
        loading, 
        getRowProps,
        sorting,
        headerComponent,
        initialState
    } = props

    const table = useReactTable({
        columns,
        data,
        sorting,
        initialState,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    const {
        getHeaderGroups,
        getRowModel
    } = table

    const renderHeader = useCallback(headerGroup => {
        return (
            <tr key={headerGroup.id}>
                {
                    headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan}>
                            {
                                header.isPlaceholder
                                    ? null
                                    : (
                                        <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler()
                                            }}>
                                                {
                                                    flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )
                                                }
                                                {
                                                    {
                                                        asc: <FontAwesomeIcon className="sortIcon" icon={faCaretUp} size="1x" color="var(--primary-color)" />,
                                                        desc: <FontAwesomeIcon className="sortIcon" icon={faCaretDown} size="1x" color="var(--primary-color)" />
                                                    }[header.column.getIsSorted().toString() || null]
                                                }
                                        </div>
                                    )
                            }
                        </th>
                    ))
                }
            </tr>
        )
    }, [])

    const renderRow = useCallback(row => (
        <tr { ...getRowProps(row) } key={row.id}>
            { 
                row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                        { flexRender(cell.column.columnDef.cell, cell.getContext()) }
                    </td>
                ))
            }
        </tr>
    ), [getRowProps])

    const renderTable = useCallback(() => {
        if (loading) {
            return <div>Carregando, aguarde...</div>
        } else {
            return (
                <table>
                    <thead>
                        { getHeaderGroups().map(renderHeader) }
                    </thead>
                    <tbody>
                        { getRowModel().rows.map(renderRow) }
                    </tbody>
                </table>
            )
        }
    }, [ loading, renderRow, renderHeader ])

    const renderHeaderComponent = useCallback(() => {
        if (typeof headerComponent === 'function')
            return headerComponent({ loading })

        return headerComponent
    }, [headerComponent, loading])

    return (
        <div className="Table">
            <div className="tableContainer">
                <div className="tableBorder">
                    { renderHeaderComponent() }
                    <div className="tableWrapper">
                        { renderTable() }
                    </div>
                </div>
            </div>
        </div>
    )
}

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.array,
    getRowProps: PropTypes.func,
    loading: PropTypes.bool,
    headerComponent: PropTypes.oneOfType([ PropTypes.node, PropTypes.func ])
}

export default Table