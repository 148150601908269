
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'

import './openInvoice.css'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../common/Button/Button'
import ButtonLink from '../common/ButtonLink/ButtonLink'
import Alert from '../common/Alert/Alert'
import { buildFileDownloadURL } from '../utils/files'
import { formatCurrency } from '../form/formatters'

function getReferenceMonth(date) {
    let [ _, month, year ] = date.split('/')

    return `${month}/${year}`
}

const OpenInvoice = ({ data, thereAreMore, onDetailedInvoice }) => {
    const copyBilletCode = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(data.billet_code)
            toast.success('Código de barras copiado')
        } catch (error) {
            toast.errir('Falha ao copiar código de barras')
        }
    }, [ data ])

    return (
        <div className="OpenInvoice">
            {
                thereAreMore
                    &&
                    (
                        <Alert className="mx-3 mt-2" variant="info">
                            Existem outros débitos pendentes, favor verificar a aba <strong>Histórico</strong>.
                        </Alert>
                    )
            }
            
            <div className="row d-flex align-items-center">
                <div className="col-lg-6 left">
                    <div className="openInvoiceText">
                        <FontAwesomeIcon className="me-2" icon={faClock} size="1x" color="#FFC200" />
                        Fatura em aberto
                    </div>
                    <div className="invoiceValue">{ formatCurrency(data.value) }</div>

                    <div className="invoiceDetails">
                        <span>Vencimento: {data.due_date}</span><br />
                        <span>Mês referência: { getReferenceMonth(data.service_date) }</span>
                    </div>

                    <div className="showDetailedInvoice" onClick={onDetailedInvoice}>
                            <span className="plusIndicator">+</span>
                            Veja sua fatura detalhada
                    </div>
                </div>
                <div className="col-lg-6 right d-flex flex-column justify-content-center align-items-center">
                    <ButtonLink
                        to={buildFileDownloadURL(data.billet_annex_id)}
                        anchor
                        target="_blank"
                        className="invoiceBtn"
                        rounded="full">
                        Gerar boleto
                    </ButtonLink>
                    <Button
                        className="invoiceBtn"
                        variant="outline-primary"
                        rounded="full"
                        onClick={ copyBilletCode }>Copiar código de barras</Button>
                </div>
            </div>
        </div>
    )
}

OpenInvoice.propTypes = {
    thereAreMore: PropTypes.bool,
    data: PropTypes.shape({
        id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
        due_date: PropTypes.string,
        service_date: PropTypes.string,
        billet_annex_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.any ]),
        invoice_annex_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.any ]),
        value: PropTypes.number
    }).isRequired,
    onDetailedInvoice: PropTypes.func
}

export default OpenInvoice