import React from 'react'
import BackButton from '../common/BackButton/BackButton'
import './pageNotFound.css'

const PageNotFound = props => (
    <section
        className="PageNotFound">
        <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            <h1 className="headingXl c-text text-center mb-5" >
                <span className="c-primary">Ops!</span><br />
                Página não encontrada.
            </h1>

            <BackButton />
        </div>
    </section>
)

export default PageNotFound