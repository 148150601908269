import React, { useMemo } from 'react'
import './Divider.css'
import PropTypes from 'prop-types'

const Divider = props => {
    const styles = useMemo(() => {
        return {
            ...props.style,
            backgroundColor: props.color || '#d1d1d1',
            height: props.height || '1px',
            width: props.width || '100%'
        }
    }, [ props.style, props.color, props.width, props.height ])

    return (
        <div
            {...props}
            style={styles}
            className={`${props.className || ''}  Divider`}></div>
    )
}

Divider.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
}

export default Divider