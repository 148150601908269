import React, { Children, cloneElement } from 'react'
import './TabContent.css'

const TabContent = props => {
    const { activeTab } = props

    return (
        <div className="TabContent">
            {
                Children.map(props.children, (child, i) => {
                    return cloneElement(child, {
                        style: {
                            transform: `translateX(${(i - activeTab) * 100}%)`,
                            transition: 'transform .4s ease'
                        }
                    })
                })
            }
        </div>
    )
}

export default TabContent