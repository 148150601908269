import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import './Badge.css'

const Badge = props => {
    const { className, variant } = props
    
    const classes = useMemo(() => {
        let c = `${className} Badge`

        switch (variant) {
            case 'info':
                c += ' badgeInfo'
                break
            case 'secondary':
                c += ' badgeSecondary'
                break
            case 'danger':
                c += ' badgeDanger'
                break
            case 'warning':
                c += ' badgeWarning'
                break
            case 'success':
                c += ' badgeSuccess'
                break
            default:
                c += ' badgePrimary'
        }

        return c
    }, [variant, className])

    return (
        <span className={classes}>
            { props.children } 
        </span>
    )
}

Badge.propTypes = {
    variant: PropTypes.oneOf([
        'primary',
        'secondary', 
        'danger',
        'warning',
        'success',
        'info'
    ])
}

export default Badge