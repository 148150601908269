import React, {
    useEffect, 
    useMemo,
    useCallback,
    useState
} from 'react'

import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchCompanies,
    newRow,
    saveCompany,
    removeCompany,
    removeCompanies,
    setEditableRow,
} from './companiesActions'

import Table from '../common/Table/Table'

import { useSelection } from '../hooks/useSelection'

import TableHeader from './tableHeader'

import CompanyId from './cells/companyId'
import CompanyName from './cells/companyName'
import CompanyCnpj from './cells/companyCnpj'

import {
    DATA_KEY_ID,
    DATA_KEY_CNPJ,
    DATA_KEY_NAME
} from './companiesConsts'

import { isSuper } from '../auth/isSuper'
import SelectionToggler from '../tables/selectionToggler'
import RowCheck from '../tables/rowCheck'
import CompanyActions from './cells/companyActions'
import DeleteEntityDialog from '../tables/deleteEntityDialog'
import PageNotFound from '../404/pageNotFound'
import Translator from '../common/I18n/Translator'

let CompaniesTableForm = props => {
    const {
        companies: { data, loading, editableRowIndex },
        auth: { user },
        handleSubmit,
        fetchCompanies,
        saveCompany,
        removeCompany,
        removeCompanies,
        newRow,
        setEditableRow,
        initialize
    } = props

    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ productsToDelete, setProductsToDelete ] = useState([])
    const [ sorting, setSorting ] = useState([])

    const {
        selectedRows,
        clearSelections,
        toggleRowsSelection,
        selectRow
    } = useSelection([], data)

    // Exibe documentos e limpa seleções

    useEffect(() => {
        fetchCompanies(user)
        clearSelections()
        setEditableRow(-1)
    }, [
        user
    ])

    useEffect(() => {
        initialize(data[editableRowIndex])
    }, [ initialize, data, editableRowIndex ])

    const onSubmit = useCallback(values => {
        saveCompany(values, user)
    }, [saveCompany, user])

    const openDeleteDialog = useCallback(companiesId => {
        setShowDeleteDialog(true)
        setProductsToDelete([ ...companiesId ])
    }, [ setProductsToDelete ])

    const closeDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
        setProductsToDelete([])
        clearSelections()
    }, [ setShowDeleteDialog, setProductsToDelete, clearSelections ])

    const handleDelete = useCallback(() => {
        setProductsToDelete(companiesId => {
            if (companiesId.length === 1)
                removeCompany(companiesId[0], user)
            else
                removeCompanies(companiesId, user)

            setShowDeleteDialog(false)

            return []
        })
    }, [
        setProductsToDelete,
        setShowDeleteDialog,
        removeCompanies,
        removeCompany,
        user
    ])

    const renderHeaderComponent = useCallback(() => (
        <TableHeader
            selectedRows={selectedRows}
            loading={loading}
            editableRowIndex={editableRowIndex}
            onAdd={() => {
                newRow()
                clearSelections()
            } }
            onRemove={() => openDeleteDialog(selectedRows)} />
    ), [
        loading,
        selectedRows,
        clearSelections,
        removeCompanies,
        editableRowIndex,
        newRow,
        user,
        setProductsToDelete
    ])

    const renderSelectionToggler = useCallback(() => (
        <SelectionToggler
            checked={ selectedRows.length > 0 && selectedRows.length === data.length }
            disabled={ editableRowIndex !== -1 }
            value={ selectedRows.length > 0 && selectedRows.length === data.length ? 'true' : 'false' }
            onChange={toggleRowsSelection} />
    ), [toggleRowsSelection, selectedRows, editableRowIndex, data])

    const renderRowCheck = useCallback(info => {
        return (
            <RowCheck
                { ...info }
                editableRowIndex={editableRowIndex}
                selectedRows={selectedRows}
                onChange={ id => selectRow(id) } />
        )
    }, [editableRowIndex, selectedRows, selectRow])

    const renderId = useCallback(info => (
        <CompanyId {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderName = useCallback(info => (
        <CompanyName
            { ...info }
            editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderCnpj = useCallback(info => (
        <CompanyCnpj
            {...info}
            editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderActions = useCallback(info => (
        <CompanyActions
            {...info}
            editableRowIndex={editableRowIndex}
            selectedRows={selectedRows}
            onCancel={() => setEditableRow(-1)}
            onEdit={ index => setEditableRow(index) }
            onRemove={ () => openDeleteDialog([ info.row.original.id ]) } />
    ),
    [
        editableRowIndex,
        selectedRows,
        setEditableRow,
        setShowDeleteDialog,
        user
    ])

    const columns = useMemo(() => ([
        {
            id: 'actions',
            header: renderSelectionToggler,
            cell: renderRowCheck
        },
        {
            id: "id",
            header: () => <Translator path="tables.column_id" />,
            accessorKey: DATA_KEY_ID,
            cell: renderId
        },
        {
            id: "name",
            header: () => <Translator path="tables.column_name" />,
            accessorKey: DATA_KEY_NAME,
            cell: renderName
        },
        {
            id: "cnpj",
            header: () => <Translator path="tables.column_cnpj" />,
            accessorKey: DATA_KEY_CNPJ,
            cell: renderCnpj
        },
        {
            id: "action",
            header: () => <Translator path="tables.column_action" />,
            cell: renderActions
        }
    ]), [
        renderSelectionToggler,
        renderRowCheck,
        renderId,
        renderName,
        renderCnpj,
        renderActions
    ])

    const getRowProps = useCallback(({index}) => ({
        className: `${index === editableRowIndex ? 'active' : ''}`
    }), [ editableRowIndex ])

    return isSuper(user)
    ? (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table
                    data={data}
                    columns={columns}
                    loading={loading}
                    state={{
                        sorting
                    }}
                    getRowProps={getRowProps}
                    headerComponent={renderHeaderComponent} />
            </form>

            <DeleteEntityDialog
                    entityName={
                        productsToDelete.length > 1
                            ? <Translator path="services.companies" />
                            : <Translator path="companies.company" />
                    }
                    open={showDeleteDialog}
                    onClose={closeDeleteDialog}
                    onNegative={closeDeleteDialog}
                    onPositive={handleDelete} />
        </div>
        
    )
    : <PageNotFound />
}

CompaniesTableForm = reduxForm({
    form: 'company',
})(CompaniesTableForm)

const mapStateToProps = state => ({
    auth: state.auth,
    companies: state.companies,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchCompanies,
    removeCompany,
    removeCompanies,
    setEditableRow,
    newRow,
    saveCompany
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTableForm)