import React, { useEffect, useRef } from 'react'

import Feedback from '../Feedback/Feedback'
import './CellField.css'

const CellField = (props) => {
    const ref = useRef(null)
    const {
        type,
        focus,
        input,
        meta: {
            error
        }
     } = props

    useEffect(() => {
        if (ref.current) {
            if (focus) {
                ref.current.focus()
            }
        }
    }, [focus, ref])

    return (
        <div className="CellField">
            <input
                className={ error ? 'isInvalid': '' }
                ref={ref}
                type={type}
                { ...input } />
            { error && <Feedback type="invalid">{error}</Feedback> }
        </div>
    )
}

export default CellField