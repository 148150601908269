import { PAYMENT_STATUS_PENDING } from "./paymentStatus";

export function getPendingPayments(payments) {
    // Filtra pagamentos pendentes
    const pendingPayments = payments.filter(p => p.status === PAYMENT_STATUS_PENDING)

    if (pendingPayments.length > 1)
        return pendingPayments.sort((p1, p2) => p1.due_date.localeCompare(p2.due_date)) // Ordena pagamentos pela data de vencimento mais antiga

    return pendingPayments
}