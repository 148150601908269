import React, {
    useEffect, 
    useMemo,
    useCallback,
    useState
} from 'react'

import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchUsers,
    newRow,
    saveUser,
    removeUser,
    removeUsers,
    setEditableRow,
} from './adminUsersActions'

import Table from '../common/Table/Table'
import TableHeader from './tableHeader'

import UserId from './cells/userId'
import UserName from './cells/userName'
import UserEmail from './cells/userEmail'
import UserPassword from './cells/userPassword'
import UserActions from './cells/userActions'
import SelectionToggler from '../tables/selectionToggler'
import RowCheck from '../tables/rowCheck'
import DeleteEntityDialog from '../tables/deleteEntityDialog'

import {
    DATA_KEY_ID,
    DATA_KEY_NAME,
    DATA_KEY_EMAIL,
    DATA_KEY_PASSWORD
} from './usersConsts'

import { useSelection } from '../hooks/useSelection'
import { isSuper } from '../auth/isSuper'
import PageNotFound from '../404/pageNotFound'

let AdminUsersTableForm = props => {
    const {
        users: { data, loading, editableRowIndex },
        auth: { user },
        handleSubmit,
        fetchUsers,
        saveUser,
        removeUser,
        removeUsers,
        newRow,
        setEditableRow,
        initialize
    } = props

    const [ sorting, setSorting ] = useState([])
    const columnVisibility        = useMemo(() => editableRowIndex === -1 ? { 'Senha': false } : {}, [ editableRowIndex ])
    const dataWithoutCurrentUser  = useMemo(() => data.filter(u => u.id !== user.id), [ data ]) // Dados filtrados sem o usuário atual (logado)

    const {
        selectedRows,
        clearSelections,
        toggleRowsSelection,
        selectRow
    } = useSelection([], dataWithoutCurrentUser)

    // Exibe documentos e limpa seleções

    useEffect(() => {
        fetchUsers(user)
        clearSelections()
        setEditableRow(-1)
    }, [
        user
    ])

    useEffect(() => {
        initialize(data[editableRowIndex])
    }, [ initialize, data, editableRowIndex ])


    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ productsToDelete, setProductsToDelete ] = useState([])

    const openDeleteDialog = useCallback(usersId => {
        setShowDeleteDialog(true)
        setProductsToDelete([ ...usersId ])
    }, [ setProductsToDelete ])

    const closeDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
        setProductsToDelete([])
        clearSelections()
    }, [ setShowDeleteDialog, setProductsToDelete, clearSelections ])

    const handleDelete = useCallback(() => {
        setProductsToDelete(usersId => {
            if (usersId.length === 1)
                removeUser(usersId[0], user)
            else
                removeUsers(usersId, user)

            setShowDeleteDialog(false)

            return []
        })
    }, [
        setProductsToDelete,
        setShowDeleteDialog,
        removeUsers,
        removeUser,
        user
    ])

    const onSubmit = useCallback(values => {
        saveUser(values, user)
    }, [saveUser, user])

    const renderHeaderComponent = useCallback(() => (
        <TableHeader
            selectedRows={selectedRows}
            loading={loading}
            editableRowIndex={editableRowIndex}
            onAdd={() => {
                newRow()
                clearSelections()
            } }
            onRemove={() => openDeleteDialog([ ...selectedRows ]) } />
    ), [
        loading,
        selectedRows,
        clearSelections,
        removeUsers,
        editableRowIndex,
        openDeleteDialog,
        newRow,
        user
    ])

    const renderSelectionToggler = useCallback(() => (
        <SelectionToggler
            checked={ selectedRows.length > 0 && selectedRows.length === dataWithoutCurrentUser.length }
            disabled={ editableRowIndex !== -1 }
            value={ selectedRows.length === dataWithoutCurrentUser.length ? 'true' : 'false' }
            onChange={toggleRowsSelection} />
    ), [toggleRowsSelection, selectedRows, editableRowIndex, dataWithoutCurrentUser])

    const renderRowCheck = useCallback(info => {
        const { row: { original } } = info

        return user.id !== original.id
            ? (
                <RowCheck
                    { ...info }
                    editableRowIndex={editableRowIndex}
                    selectedRows={selectedRows}
                    onChange={ id => selectRow(id) } />
            )
            : null
    }, [editableRowIndex, selectedRows, selectRow, user])

    const renderId = useCallback(info => (
        <UserId {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderName = useCallback(info => (
        <UserName
            { ...info }
            editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderEmail = useCallback(info => (
        <UserEmail
            {...info}
            editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderPassword = useCallback(info => (
        <UserPassword
            {...info}
            editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderActions = useCallback(info => (
        <UserActions
            {...info}
            editableRowIndex={editableRowIndex}
            selectedRows={selectedRows}
            onCancel={() => setEditableRow(-1)}
            onEdit={ index => setEditableRow(index) }
            showRemove={ user.id !== info.row.original.id }
            onRemove={ id => openDeleteDialog([ id ]) } />
    ),
    [
        editableRowIndex,
        selectedRows,
        setEditableRow,
        removeUser,
        openDeleteDialog,
        user
    ])

    const columns = useMemo(() => ([
        {
            id: 'actions',
            header: renderSelectionToggler,
            cell: renderRowCheck
        },
        {
            header: 'Id',
            accessorKey: DATA_KEY_ID,
            cell: renderId
        },
        {
            header: 'Nome',
            accessorKey: DATA_KEY_NAME,
            cell: renderName
        },
        {
            header: 'E-mail',
            accessorKey: DATA_KEY_EMAIL,
            cell: renderEmail
        },
        {
            header: 'Senha',
            accessorKey: DATA_KEY_PASSWORD,
            cell: renderPassword
        },
        {
            header: 'Ação',
            cell: renderActions
        }
    ]), [
        renderSelectionToggler,
        renderRowCheck,
        renderId,
        renderName,
        renderEmail,
        renderPassword,
        renderActions
    ])

    const getRowProps = useCallback(({index}) => ({
        className: `${index === editableRowIndex ? 'active' : ''}`
    }), [ editableRowIndex ])

    return isSuper(user)
    ? (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table
                    data={data}
                    columns={columns}
                    loading={loading}
                    state={{
                        sorting
                    }}
                    initialState={{
                        columnVisibility
                    }}
                    getRowProps={getRowProps}
                    headerComponent={renderHeaderComponent} />
            </form>
            <DeleteEntityDialog
                entityName={productsToDelete.length > 1 ? "Usuários" : "Usuário"}
                open={showDeleteDialog}
                onClose={closeDeleteDialog}
                onNegative={closeDeleteDialog}
                onPositive={handleDelete} />
        </div>
    )
    : <PageNotFound />
}

AdminUsersTableForm = reduxForm({
    form: 'users',
})(AdminUsersTableForm)

const mapStateToProps = state => ({
    auth: state.auth,
    users: state.users,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchUsers,
    removeUser,
    removeUsers,
    setEditableRow,
    newRow,
    saveUser
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersTableForm)