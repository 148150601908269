import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'
import CellField from '../../common/Table/CellField'

import { DATA_KEY_NAME } from '../companiesConsts'
import { required } from '../../form/validators'

const CompanyName = props => {
    const { getValue, row: { index }, editableRowIndex } = props
        
    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={getValue()}
            renderField={ () => (
                <Field
                    name={DATA_KEY_NAME}
                    component={CellField}
                    validate={required}
                    focus
                    />
            ) } />
    )
}

CompanyName.propTypes = {
    editableRowIndex: PropTypes.number   
}

export default CompanyName