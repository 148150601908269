import React, { Children, cloneElement } from 'react'
import './TabList.css'

import PropTypes from 'prop-types'

const TabList = props => {
    const { onTabClick, activeTab } = props
    
    return (
        <div className="TabList">
            {
                Children.map(props.children, (child, i) => {
                    return cloneElement(child, { onClick: () => onTabClick(i), active: activeTab === i })
                })
            }
        </div>
    )
}

TabList.propTypes = {
    onTabClick: PropTypes.func.isRequired
}


export default TabList