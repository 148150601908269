import React from 'react'

import PageSection from '../layout/PageSection/PageSection'
import DocumentsTableForm from './documentsTableForm'

const Documents = props => {
    return (
        <section className="Documents">
            <PageSection
                containerFluid
                title="Documentações"
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <DocumentsTableForm />
                    </div>
                </div>
            </PageSection>
        </section>
    )
}

export default Documents