import { toast } from 'react-toastify'
import { apiClient } from '../config/api'
import {
    ACTION_TYPE_COMPANIES_FETCHED,
    ACTION_TYPE_COMPANIES_FETCHING,
    ACTION_TYPE_COMPANIES_REMOVED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE
} from './companiesActionTypes'

import { logout } from '../auth/authActions'
import isVirtualRow from '../tables/isVirtualRow'
import { tokenExpired } from '../auth/tokenExpired'

import { parseCnpj } from '../form/parsers'

export function fetchCompanies(user) {
    const endpoint = `/api/accounts/${user.account_id}/companies`

    return dispatch => {
        dispatch(fetchingCompanies(true))

        apiClient.get(endpoint)
            .then(resp => {
                dispatch({ type: ACTION_TYPE_COMPANIES_FETCHED, payload: resp.data })
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao consultar empresas')
                }
            })
            .finally(() => dispatch(fetchingCompanies(false)))
    }
}

export function fetchingCompanies(value) {
    return { type: ACTION_TYPE_COMPANIES_FETCHING, payload: value }
}

export function saveCompany(company, user) {
    const { id, name, cnpj } = company

    const parsedCnpj = parseCnpj(cnpj)

    return dispatch => {
        if (id && !isVirtualRow(id)) {
            // Atualiza

            apiClient.put(
                `/api/accounts/${user.account_id}/companies/${id}`,
                { name, cnpj: parsedCnpj },
            ).then(() => {
                toast.success('Empresa atualizada')
                dispatch(setEditableRow(-1))
                dispatch(fetchCompanies(user))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao atualizar empresa')
                }
            })
        } else {
            // Cria

            apiClient.post(
                `/api/accounts/${user.account_id}/companies`,
                { name, cnpj: parsedCnpj },
            ).then(() => {
                toast.success('Empresa criada')
                dispatch(setEditableRow(-1))
                dispatch(fetchCompanies(user))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao criar empresa')
                }
            })
        }
    }
}

// Remove empresa

export function removeCompany(companyId, user) {
    return dispatch => {
        if (!isVirtualRow(companyId)) {
            apiClient.delete(`/api/accounts/${user.account_id}/companies/${companyId}`)
                .then(() => {
                    toast.success('Empresa excluída')
                    dispatch(fetchCompanies(user))
                })
                .catch(err => {
                    if (tokenExpired(err)) { // Verifica se o token expirou
                        dispatch(logout()) // Desloga usuário
                        toast.error('Sessão expirada')
                    } else {
                        toast.error('Falha ao excluir empresa')
                    }
                })
        } else {
            toast.success('Empresa excluída')
            dispatch({ type: ACTION_TYPE_COMPANIES_REMOVED, payload: companyId })
        }
    }
}

// Remove empresas

export function removeCompanies(companiesId, user) {
    return dispatch => {
        const requests = companiesId.map(companyId => {
            return !isVirtualRow(companyId)
                ? (
                    apiClient.delete(
                        `/api/accounts/${user.account_id}/companies/${companyId}`
                    )
                )
                : (
                    dispatch({ type: ACTION_TYPE_COMPANIES_REMOVED, payload: companyId })
                )
        })

        const allPromises = Promise.all(requests)

        allPromises.then(() => {
            if (companiesId.length === 1)
                toast.success('Empresa excluída')
            else {
                toast.success(`${companiesId.length} empresas excluídas`)
            }

            dispatch(fetchCompanies(user))
        }).catch(err => {
            if (tokenExpired(err)) { // Verifica se o token expirou
                dispatch(logout()) // Desloga usuário
                toast.error('Sessão expirada')
            } else {
                if (companiesId.length === 1) {
                    toast.error('Falha ao excluir empresa')
                } else {
                    toast.error('Falha ao excluir empresas')
                }
            }
        })
    }
}

// Seta a linha que está sendo editada

export function setEditableRow(index) {
    return { type: ACTION_TYPE_TABLE_SET_ROW_EDITABLE, payload: index }
}

export function newRow() {
    return dispatch => {
        dispatch({ type: ACTION_TYPE_TABLE_NEW_ROW })
    }
}