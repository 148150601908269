import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'


const DownloadButton = props => (
    <a href={ props.targetURL } target="_blank" title="Download">
        <FontAwesomeIcon icon={ faDownload } size="lg" color="#000" />
    </a>
)

DownloadButton.propTypes = {
    targetURL: PropTypes.string.isRequired
}

export default DownloadButton