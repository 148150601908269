export function formatDate(value) {
    if (!value)
        return value

    let dateRegExp = /^([0-9]{2}|[0-9]{4})-[0-9]{2}-[0-9]{2}$/

    if (dateRegExp.test(value)) {
        let [ year, month, day ] = value.split('-')

        return `${day}/${month}/${year}`
    } else {
        return value
    }
}

export function formatCnpj(value) {
    if (!value)
        return value

    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
}

export function formatCurrency(value) {
    if (!value) return value

    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}