import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import './ButtonLink.css'

import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const ButtonLink = props => {
    const className = useMemo(() => {
        let classes = `${props.className || ''} ButtonLink`

        switch (props.variant) {
            case 'primary':
                classes += ' btnPrimary'
                break
            case 'secondary':
                classes += ' btnSecondary'
                break
            case 'light':
                classes += ' btnLight'
                break
        }

        switch (props.size) {
            case 'xs':
                classes += ' btnXs'
                break
            case 'lg':
                classes += ' btnLg'
                break
            case 'xl':
                classes += ' btnXl'
                break
            default:
                classes += ' btnMd'
        }

        switch (props.rounded) {
            case 'xs':
                classes += ' btnRoundedXs'
                break
            case 'sm':
                classes += ' btnRoundedSm'
                break
            case 'md':
                classes += ' btnRoundedMd'
                break
            case 'lg':
                classes += ' btnRoundedLg'
                break
            case 'xl':
                classes += ' btnRoundedXl'
                break
            case 'full':
                classes += ' btnRoundedFull'
                break
        }

        return classes
    }, [
        props.variant,
        props.className,
        props.size,
        props.rounded
    ])

    if (props.anchor) {
        return (
            <a href={props.to} className={className} target={props.target}>{props.children}</a>
        )
    }

    if (props.hash) {
        return (
            <HashLink to={props.to} className={className} smooth>{ props.children }</HashLink>
        )
    } else {
        return (
            <Link to={props.to} className={className}>{ props.children }</Link>
        )
    }
}

ButtonLink.propTypes = {
    to: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([ 'primary', 'secondary', 'light' ]),
    hash: PropTypes.bool,
    size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]),
    rounded: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl', 'full', 'none' ]),
    anchor: PropTypes.bool
}

ButtonLink.defaultProps = {
    variant: 'primary',
    to: '#',
    size: 'md',
    rounded: 'none'
}


export default ButtonLink