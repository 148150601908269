import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'
import CellField from '../../common/Table/CellField'

import { DATA_KEY_TECHNICAL_MANAGER } from '../schedulingConsts'
import { required } from '../../form/validators'

const SchedulingTechnicalManager = props => {
    const { getValue, row: { index }, editableRowIndex } = props
    
    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={getValue()}
            renderField={ () => (
                <Field
                    name={DATA_KEY_TECHNICAL_MANAGER}
                    validate={required}
                    component={CellField} />
            ) } />
    )
}

SchedulingTechnicalManager.propTypes = {
    editableRowIndex: PropTypes.number,
    items: PropTypes.array,
    loading: PropTypes.bool
}

export default SchedulingTechnicalManager