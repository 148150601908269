import React from 'react'
import PropTypes from 'prop-types'


import { Field } from 'redux-form'
import { formatDate } from '../../form/formatters'
import { normalizeDate } from '../../form/normalizers'
import { isDate } from '../../form/validators'

import { DATA_KEY_DUE_DATE } from '../paymentConsts'

import Cell from '../../common/Table/Cell'
import CellField from '../../common/Table/CellField'

const PaymentDueDate = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={formatDate(getValue())}
            renderField={ () => (
                <Field
                    name={DATA_KEY_DUE_DATE}
                    component={CellField}
                    normalize={normalizeDate}
                    validate={isDate}
                    />
            ) } />
    )
}

PaymentDueDate.propTypes = {
    editableRowIndex: PropTypes.number,
}

export default PaymentDueDate