import { APPLICATION_PDF } from '../files/mimeTypes'
import { isFileMime } from '../form/validators'

export const asyncValidate = values => {
    if (values.file) {
        return isFileMime(values.file, [APPLICATION_PDF])
            .then(null, error => {
                throw { file: error }
            })
    }

    return Promise.resolve()
}