import React from 'react'
import PropTypes from 'prop-types'

const RowCheck = props => {
    const {
        row: { original },
        editableRowIndex,
        selectedRows,
        onChange
    } = props
    const checked = selectedRows.includes(original.id)
        
    return (
        <div className={`${editableRowIndex !== -1 ? 'disabled' : '' } d-flex justify-content-center w-100 h-100`}>
            <input
                name="selected_rows"
                type="checkbox"
                checked={ checked }
                value={original.id}
                disabled={ editableRowIndex !== -1 }
                onChange={() => onChange ? onChange(original.id) : false}
                 />
        </div>
    )
}

RowCheck.propTypes = {
    editableRowIndex: PropTypes.number,
    selectedRows: PropTypes.array,
    onChange: PropTypes.func,
}

export default RowCheck