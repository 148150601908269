import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import {
    fetchContracts,
    saveContract,
    removeContracts,
    removeContract,
    setEditableRow,
    newRow
} from './contractsActions'

import ActionButton from '../common/ActionButton/ActionButton'
import Button from '../common/Button/Button'
import CellField from '../common/Table/CellField'
import Cell from '../common/Table/Cell'

import {
    faPencil,
    faTrashCan,
    faSave,
    faXmark,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import FileInput from '../common/FileInput/FileInput'

import {
    DATA_KEY_ANNEX_ID,
    DATA_KEY_ID,
    DATA_KEY_DATE,
    DATA_KEY_UNIT,
    DATA_KEY_STATUS,
    DATA_KEY_TECHNICAL_MANAGER
} from './contractsConsts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { normalizeDate } from '../form/normalizers'
import { formatDate } from '../form/formatters'
import {
    fileMaxSize,
    isDate,
    required
} from '../form/validators'
import { asyncValidate } from './asyncValidate'

import Table from '../common/Table/Table'
import { useSelection } from '../hooks/useSelection'
import DownloadButton from '../common/DownloadButton/DownloadButton'
import { buildFileDownloadURL } from '../utils/files'
import { useParams } from 'react-router-dom'
import { isSuper } from '../auth/isSuper'
import DeleteEntityDialog from '../tables/deleteEntityDialog'

import isVirtualRow from '../tables/isVirtualRow'
import hasPermission from '../auth/hasPermission'

let ContractTableForm = (props) => {
    const {
        fetchContracts,
        saveContract,
        removeContract,
        removeContracts,
        setEditableRow,
        newRow,
        initialize,
        contracts: { 
            data,
            loading,
            editableRowIndex
        },
        auth: { user },
        handleSubmit
    } = props

    const { company_id }          = useParams() 
    const [ sorting, setSorting ] = useState([])
    const columnVisibility        = useMemo(() => !isSuper(user) ? { actions: false, 'Ação': false } : {}, []) // Esconde colunas administrativas

    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ productsToDelete, setProductsToDelete ] = useState([])

    const [ file, setFile ] = useState(null)
    const {
        selectedRows,
        clearSelections,
        toggleRowsSelection,
        selectRow
    } = useSelection([], data)

    useEffect(() => {
        initialize(data[editableRowIndex])
    }, [ initialize, data, editableRowIndex ])

    useEffect(() => {
        clearSelections()
    }, [clearSelections])

    useEffect(() => {
        // Limpa edição ao atualizar a página

        setEditableRow(-1)

        // Limpa arquivo

        setFile(null)
    }, [ setEditableRow, setFile ])

    useEffect(() => {
        fetchContracts(user, company_id)
    }, [fetchContracts, user, company_id])

    const handleFile = useCallback(files => {
        setFile(files[0])
    }, [setFile])

    const onSubmit = useCallback(values => {
        saveContract({ ...values, file }, user, company_id)
    }, [saveContract, user, company_id])

    const openDeleteDialog = useCallback(contractsId => {
        setShowDeleteDialog(true)
        setProductsToDelete([ ...contractsId ])
    }, [ setProductsToDelete ])

    const closeDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
        setProductsToDelete([])
        clearSelections()
    }, [ setShowDeleteDialog, setProductsToDelete, clearSelections ])

    const handleDelete = useCallback(() => {
        setProductsToDelete(contractsId => {
            if (contractsId.length === 1)
                removeContract(contractsId[0], user, company_id)
            else
                removeContracts(contractsId, user, company_id)

            setShowDeleteDialog(false)

            return []
        })
    }, [
        setProductsToDelete,
        setShowDeleteDialog,
        removeContracts,
        removeContract,
        company_id,
        user
    ])

    const renderHeaderComponent = useCallback(({ loading }) => {
        return isSuper(user) ?
        (
            <div className="d-flex py-2 justify-content-end">
                <Button
                    className="me-2" 
                    type="button"
                    disabled={loading}
                    rounded="full"
                    onClick={() => {
                    newRow()
                    clearSelections()
                    setFile(null)
                }}>
                    <FontAwesomeIcon className="me-2" icon={ faPlus } color="#fff" />
                    Adicionar
                </Button>

                <Button
                    variant="danger"
                    type="button"
                    rounded="full"
                    disabled={ (loading || editableRowIndex !== -1) || selectedRows.length === 0}
                    onClick={ () => openDeleteDialog([ ...selectedRows ])}>
                    <FontAwesomeIcon className="me-2" icon={ faTrashCan } color="#fff" />
                    Excluir
                </Button>
            </div>
        )
        : null
    }, [
        newRow,
        editableRowIndex,
        selectedRows,
        removeContracts,
        data,
        user,
        company_id
    ])

    const renderSelectionToggler = useCallback(() => (
        <div className="d-flex justify-content-center w-100 h-100">
            <input
                name="all_selected"
                type="checkbox"
                checked={ selectedRows.length > 0 && selectedRows.length === data.length }
                disabled={ selectedRows.length > 0 && selectedRows.length === data.length }
                value={ selectedRows.length === data.length ? 'true' : 'false' }
                onChange={toggleRowsSelection} />
        </div>
    ), [toggleRowsSelection, selectedRows, editableRowIndex, data])

    const renderRowSelection = useCallback(({ row: { original } }) => {
        const checked = selectedRows.includes(original.id)
        
        return (
            <div className={`${editableRowIndex !== -1 ? 'disabled' : '' } d-flex justify-content-center w-100 h-100`}>
                <input
                    name="selected_rows"
                    type="checkbox"
                    checked={ checked }
                    value={original.id}
                    disabled={ editableRowIndex !== -1 }
                    onChange={() => selectRow(original.id)} />
            </div>
        )
    }, [selectRow, selectedRows, editableRowIndex])

    const renderId = useCallback(info => {
        const { getValue, row: { index } } = info

        return (
            <Cell
                editable={ index === editableRowIndex }
                plainText={ !isVirtualRow(getValue()) ? getValue() : '' }
                renderPlain={ ({ plainText }) => <div className="disabled d-flex px-3 align-items-center w-100 h-100">{ plainText }</div>  }
                renderField={ ({ plainText }) => (
                    <div className="disabled d-flex align-items-center w-100 h-100">
                        <Field
                            name="id"
                            type="hidden"
                            component="input" />
                        <span>{ plainText }</span>
                    </div>
                ) } />
        )
    }, [editableRowIndex])

    const renderServiceDate = useCallback(info => {
        const { getValue, row: { index } } = info

        return (
            <Cell
                editable={ index === editableRowIndex }
                plainText={formatDate(getValue())}
                renderField={ () => (
                    <Field
                        name="date"
                        focus
                        component={CellField}
                        normalize={normalizeDate}
                        validate={isDate}
                        />
                ) } />
        )
    }, [ editableRowIndex ])

    const renderUnit = useCallback(info => {
        const { getValue, row: { index } } = info

        return (
            <Cell
                editable={ index === editableRowIndex }
                plainText={getValue()}
                renderField={ () => (
                    <Field
                        name="unit"
                        validate={required}
                        component={CellField} />
                ) } />
        )
    }, [ editableRowIndex ])

    const renderTechnicalManager = useCallback(info => {
        const { getValue, row: { index } } = info

        return (
            <Cell
                editable={ index === editableRowIndex }
                plainText={getValue()}
                renderField={ () => (
                    <Field
                        name="technical_manager"
                        validate={required}
                        component={CellField} />
                ) } />
        )
    }, [ editableRowIndex ])

    const renderStatus = useCallback(info => {
        const { getValue, row: { index } } = info

        return (
            <Cell
                editable={ index === editableRowIndex }
                plainText={getValue()}
                renderField={ () => (
                    <Field
                        name="status"
                        validate={required}
                        component={CellField}>
                    </Field>
                ) } />
        )
    }, [ editableRowIndex ])

    const renderContractFile = useCallback(info => {
        const  { getValue, row: { index , original } } = info
        return (
            <Cell
                editable={ index === editableRowIndex }
                plainText={getValue()}
                renderPlain={ () => 
                    original.annex_id
                        ? (
                            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                                <DownloadButton targetURL={buildFileDownloadURL(original.annex_id)} />
                            </div>
                        )
                        : null
                }
                renderField={ () => (
                    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                        <Field
                            name="file"
                            accept="application/pdf"
                            label={ file ? file.name : 'Carregar arquivo' }
                            onChange={handleFile}
                            component={FileInput} />
                    </div>
                ) } />
        )
    }, [editableRowIndex, handleFile, file ])

    const renderActions = useCallback(info => {
        const { row } = info
        const { original } = row
        const disabled = (editableRowIndex !== -1 || selectedRows.includes(original.id))

        return (
            <div className="d-flex justify-content-center">
                {
                    editableRowIndex === row.index
                        ? (
                            <>
                                <ActionButton
                                    className="me-1"
                                    variant="success"
                                    type="submit"
                                    title="Salvar"
                                    icon={faSave} />
                                <ActionButton
                                    variant="danger"
                                    title="Cancelar"
                                    type="button"
                                    icon={faXmark}
                                    onClick={ () => {
                                        setEditableRow(-1)
                                        setFile(null)
                                    }} />
                            </>
                        )
                        : (
                            <>
                                <ActionButton
                                    className="me-1"
                                    variant="warning"
                                    title="Editar"
                                    type="button"
                                    disabled={ disabled }
                                    icon={faPencil}
                                    onClick={ () => {
                                        setEditableRow(row.index)
                                        clearSelections()
                                     } }  />
                                <ActionButton
                                    title="Excluir"
                                    type="button"
                                    variant="danger"
                                    disabled={ disabled }
                                    icon={faTrashCan}
                                    onClick={ () => openDeleteDialog([ original.id ]) } />
                            </>
                        )
                }
                
            </div>
        )
    }, [
        editableRowIndex,
        setEditableRow,
        setFile,
        clearSelections,
        removeContract,
        selectedRows,
        user,
        company_id,
        openDeleteDialog
    ])

    const columns = useMemo(() => (
        [
            {
                id: 'actions',
                header: renderSelectionToggler,
                cell: renderRowSelection
            },
            {
                header: 'Número',
                accessorKey: DATA_KEY_ID,
                cell: renderId
            },
            {
                header: 'Data do Serviço',
                accessorKey: DATA_KEY_DATE,
                cell: renderServiceDate
            },
            {
                header: 'Unidade',
                accessorKey: DATA_KEY_UNIT,
                cell: renderUnit
            },
            {
                header: 'Técnico Responsável',
                accessorKey: DATA_KEY_TECHNICAL_MANAGER,
                cell: renderTechnicalManager
            },
            {
                header: 'Status',
                accessorKey: DATA_KEY_STATUS,
                cell: renderStatus
            },
            {
                header: 'Contrato',
                accessorKey: DATA_KEY_ANNEX_ID,
                cell: renderContractFile
            },
            {
                header: 'Ação',
                cell: renderActions
            }
        ]
    ),
    [
        renderSelectionToggler,
        renderRowSelection,
        renderId,
        renderServiceDate,
        renderUnit,
        renderTechnicalManager,
        renderStatus,
        renderContractFile,
        renderActions,
        setFile,
        editableRowIndex,
        setEditableRow
    ])

    const getRowProps = useCallback(({index}) => ({
        className: `${index === editableRowIndex ? 'active' : ''}`
    }), [ editableRowIndex ])

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table
                    data={data}
                    columns={columns}
                    getRowProps={getRowProps}
                    state={{
                        sorting,
                    }}
                    initialState={{
                        columnVisibility
                    }}
                    loading={loading}
                    headerComponent={renderHeaderComponent} />
            </form>

            {
                isSuper(user) ?
                    (
                        <DeleteEntityDialog
                            entityName={productsToDelete.length > 1 ? "Contratos" : "Contrato"}
                            open={showDeleteDialog}
                            onClose={closeDeleteDialog}
                            onNegative={closeDeleteDialog}
                            onPositive={handleDelete} />
                    )
                    : null
            }
            
        </div>
    )
}

ContractTableForm = reduxForm({
    form: 'contract',
    asyncValidate,
    asyncChangeFields: [ 'file' ],
    shouldAsyncValidate: ({ trigger, initialized, pristine}) => {
        if (trigger === 'submit') {
            return !pristine || !initialized
        }

        return true
    },
})(ContractTableForm)

ContractTableForm = hasPermission(ContractTableForm)

const mapStateToProps = state => ({
    contracts: state.contracts,
    auth: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts,
    saveContract,
    removeContracts,
    removeContract,
    setEditableRow,
    newRow
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ContractTableForm)