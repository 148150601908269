import { PAYMENT_STATUS_PENDING } from "./paymentStatus"

export function sortPaymentsByStatusDueDate(payments) {
    const sortedPayments = [ ...payments ]

    return sortedPayments.sort((p1, p2) => {
        if (p1.status === PAYMENT_STATUS_PENDING
                && p2.status === PAYMENT_STATUS_PENDING) {
            return p1.due_date.localeCompare(p2.due_date)
        }

        return -1
    })
}