import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'
import FileInput from '../../common/FileInput/FileInput'
import DownloadButton from '../../common/DownloadButton/DownloadButton'

import { buildFileDownloadURL } from '../../utils/files'
import { required, fileMaxSize } from '../../form/validators'
import { DATA_KEY_INVOICE_ANNEX_ID, DATA_KEY_INVOICE_FILE } from '../paymentConsts'

const PaymentInvoiceFile = props => {
    const {
        getValue,
        row: { index , original },
        editableRowIndex,
        onChange,
        file
    } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={getValue()}
            renderPlain={ () => 
                original[DATA_KEY_INVOICE_ANNEX_ID]
                    ? (
                        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                            <DownloadButton targetURL={buildFileDownloadURL(original[DATA_KEY_INVOICE_ANNEX_ID])} />
                        </div>
                    )
                    : null
            }
            renderField={ () => (
                <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                    <Field
                        name={ DATA_KEY_INVOICE_FILE }
                        accept="application/pdf"
                        label={ file ? file.name : 'Carregar arquivo' }
                        onChange={onChange}
                        component={FileInput} />
                </div>
            ) } />
    )
}

PaymentInvoiceFile.propTypes = {
    editableRowIndex: PropTypes.number,
    file: PropTypes.any,
    onChange: PropTypes.func
}

export default PaymentInvoiceFile