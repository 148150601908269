import React, { useCallback } from 'react'

import services from '../data/services'
import PageSection from '../layout/PageSection/PageSection'
import ServiceLink from './serviceLink'
import Translator from '../common/I18n/Translator'

import { connect } from 'react-redux'
import { isSuper } from '../auth/isSuper'
import { isAdmin } from '../auth/isAdmin'

const Home = props => {
    const { auth: { user } } = props
    
    const renderServiceLink = useCallback((service) => {
        const { admin, _super } = service

        if (_super && !admin) {
            if (!isSuper(user))
                return null
        } else if (!_super && admin) {
            if (!isAdmin(user))
                return null
        } else if (_super && admin) {
            if (!isSuper(user) && !isAdmin(user))
                return null
        }

        return (
            <div key={service.to} className="col-6 col-sm-4 col-md-4 col-lg-4 mt-4 d-flex justify-content-center">
                <ServiceLink {...service} />
            </div>
        )
    }, [user])

    return (
        <section className="Home">
            <div className="container">
                <PageSection title={<Translator path="services.title" />}>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 d-flex justify-content-center">
                                    <div className="row d-flex justify-content-center">
                                        { services.map(renderServiceLink) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageSection>

            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, null)(Home)