import React, { useEffect, useRef } from 'react'
import './CellSelect.css'

import Feedback from '../Feedback/Feedback'

const CellSelect = (props) => {
    const ref = useRef(null)
    const {
        focus,
        input,
        meta: {
            error
        },
        children
     } = props

    useEffect(() => {
        if (ref.current) {
            if (focus) {
                ref.current.blur()
                ref.current.focus()
            }
        }
    }, [focus, ref])

    return (
        <div className="CellSelect">
            <select className={ error ? 'isInvalid': '' } ref={ref} { ...input }>
                { children }
            </select>
            { error && <Feedback type="invalid">{error}</Feedback> }
        </div>
    )
}

export default CellSelect