import React from 'react'
import PropTypes from 'prop-types'

import DataController from '../tables/dataController'

const TableHeader = props => {
    return (
        <div className="TableHeader">
            <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <DataController { ...props } />
                </div>
            </div>
        </div>
    )
}

TableHeader.propTypes = {
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    loading: PropTypes.bool,
    selectedRows: PropTypes.array,
    editableRowIndex: PropTypes.number
}

export default TableHeader