import React from 'react'
import PageSection from '../layout/PageSection/PageSection'
import SchedulingTableForm from './schedulingTableForm'

const Scheduling = props => {
    return (
        <section className="Scheduling">
            <PageSection
                containerFluid
                title="Agendamentos"
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <SchedulingTableForm />
                    </div>
                </div>
            </PageSection>
        </section>
    )
}

export default Scheduling