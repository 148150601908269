import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import { required } from '../../form/validators'

import { DATA_KEY_BILLET_CODE } from '../paymentConsts'

import Cell from '../../common/Table/Cell'
import CellField from '../../common/Table/CellField'

const PaymentBilletCode = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={getValue()}
            renderField={ () => (
                <Field
                    name={DATA_KEY_BILLET_CODE}
                    component={CellField}
                    validate={required}
                    />
            ) } />
    )
}

PaymentBilletCode.propTypes = {
    editableRowIndex: PropTypes.number,
}

export default PaymentBilletCode