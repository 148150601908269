import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import PropTypes from 'prop-types'

import {
    fetchCompanies
} from './companiesActions'

import PageSection from '../layout/PageSection/PageSection'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CompanySelectorFilter from './companySelectorFilter'
import { isSuper } from '../auth/isSuper'
import CompanySelectorItem from './companySelectorItem'
import { useNavigate } from 'react-router-dom'
import Spinner from '../common/Spinner/Spinner'
import { normalizeString } from '../utils/strings'
import PageNotFound from '../404/pageNotFound'

function filterCompaniesByName(company, query) {
    const normalizedName  = normalizeString(company.name.toLowerCase())
    const normalizedQuery = normalizeString(query.toLowerCase())

    return normalizedName.includes(normalizedQuery)
}

const CompanySelector = props => {
    const {
        companies: { data, loading },
        auth: { user },
        fetchCompanies,
        route
    } = props
    const navigate            = useNavigate()
    const [ query, setQuery ] = useState('')
    const filteredCompanies   = useMemo(() => {
        return query.length > 0 ? data.filter(c => filterCompaniesByName(c, query)) : data
    }, [ data, query ])

    useEffect(() => {
        if (isSuper(user)) {
            fetchCompanies(user)
        } else {
            const redirectUrl = `/${user.company_id}/${route}`

            navigate(redirectUrl)
        }

        console.log('Company Selector')
    }, [ user, route ])

    const renderCompany = useCallback(company => (
        <CompanySelectorItem className="mb-2" key={company.id} route={route} data={company} />
    ), [route])

    const renderSpinner   = useCallback(() => {
        return loading
            ? (
                <div className="d-flex my-3 justify-content-center">
                    <Spinner color="var(--primary-color)" size="3x" />
                </div>
            )
            : null
    }, [ loading ])

    const renderCompanies = useCallback(() => {
        if (!loading && filteredCompanies.length === 0) {
            return <p className="c-text text-center mt-3">Nenhum resultado para sua busca.</p>
        } else {
            return (
                <div className="companyList my-3">
                    { filteredCompanies.map(renderCompany) }
                </div>
            )
        }
    }, [ loading, filteredCompanies, renderCompany ])

    return (
        <section className="CompanySelector pb-5">
            <PageSection
                title="Selecione uma empresa abaixo"
                titleProps={{
                    className: 'text-center'
                }}
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                        <CompanySelectorFilter
                            onChange={ e => setQuery(e.target.value) } />
                        
                        { renderCompanies() }
                        { renderSpinner() }
                    </div>
                </div>
            </PageSection>
        </section>
    )
}

CompanySelector.propTypes = {
    route: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    companies: state.companies
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchCompanies
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelector)