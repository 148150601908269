import React from 'react'
import './noInvoicePayment.css'

const NoInvoicePayment = props => (
    <div className="NoInvoicePayment">
        <h3 className="headingMd c-text">
            Você não possui faturas pendentes.
        </h3>
    </div>
)

export default NoInvoicePayment