import React, { Children, cloneElement, useCallback, useState } from 'react'
import { useImperativeHandle } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

const Tabs = (props, ref) => {
    const [ activeTab, setActiveTab ] = useState(0)
    const tabRef = useRef(null)

    const showTab = useCallback((i) => (
        setActiveTab(i)
    ), [setActiveTab])

    useImperativeHandle(ref, () => ({
        showTab: i => showTab(i)
    }))

    useEffect(() => {
        if (ref && ref.current)
            ref.current.showTab = showTab
    }, [ref, showTab])
    
    return (
        <div ref={tabRef} className="Tabs">
            {
                Children.map(props.children, (child, i) => cloneElement(child, { onTabClick: showTab, activeTab }))
            }
        </div>
    )
}



export default React.forwardRef(Tabs)