import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import './Spinner.css'

const Spinner = props => (
    <FontAwesomeIcon
        className={`${props.className || ''} Spinner`}
        icon={faSpinner}
        size={props.size}
        color={props.color} />
)

Spinner.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string
}

export default Spinner