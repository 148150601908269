import React from 'react'
import PropTypes from 'prop-types'
import './Cell.css'


const Cell = props => {
    const { editable, renderPlain, renderField, plainText , className } = props

    return (
        <div className={`${className || ''} Cell`}>
            {
                editable
                    ? renderField(props)
                    : (renderPlain ? renderPlain(props) : <div className="px-3 d-flex align-items-center w-100 h-100">{ plainText }</div>)
            }
        </div>
    )
}

Cell.propTypes = {
    editable: PropTypes.bool,
    plainText: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    renderPlain: PropTypes.func,
    renderField: PropTypes.func
}

export default Cell