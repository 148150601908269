import React from 'react'
import PropTypes from 'prop-types'

import Cell from '../../common/Table/Cell'
import { Field } from 'redux-form'

import { DATA_KEY_NAME } from '../usersConsts'

import { required } from '../../form/validators'
import CellField from '../../common/Table/CellField'

const UserEmail = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={ getValue() }
            renderPlain={ ({ plainText }) => <div className="d-flex px-3 align-items-center w-100 h-100">{ plainText }</div>  }
            renderField={ () => (
                <div className="d-flex align-items-center w-100 h-100">
                    <Field
                        name={DATA_KEY_NAME}
                        validate={required}
                        component={CellField} />
                </div>
            ) } />
    )
}

UserEmail.propTypes = {
    editableRowIndex: PropTypes.number
}

export default UserEmail