import React from 'react'

const SelectionToggler = props => (
    <div className="d-flex justify-content-center w-100 h-100">
        <input
            { ...props }
            name="all_selected"
            type="checkbox" />
    </div>
)

export default SelectionToggler