import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'
import Spinner from '../../common/Spinner/Spinner'

import { DATA_KEY_COMPANY_ID } from '../documentsConsts'

const DocumentCompany = props => {
    const { getValue, row: { index }, editableRowIndex, loading, items } = props
    
    const company = useMemo(() => items ? items.find(item => item.id === getValue()) : null, [ items, getValue ])
    
    return (
        <Cell
            className="PaymentCompany"
            editable={ index === editableRowIndex }
            plainText={ company ? company.name : ''}
            renderPlain={ ({ plainText }) => <div className="disabled d-flex px-3 align-items-center w-100 h-100">{ plainText }</div> }
            renderField={ ({ plainText }) => {
                return loading
                    ? <div className="d-flex h-100 w-100 justify-content-center align-items-center"><Spinner size="1x" color="var(--primary-color)" /></div>
                    : (
                        <div className="disabled d-flex align-items-center w-100 h-100">
                            <Field
                                name={DATA_KEY_COMPANY_ID}
                                type="hidden"
                                component="input" />
                            <div className='px-3'>{ plainText }</div>
                        </div>
                    )
            } } />
    )
}

DocumentCompany.propTypes = {
    editableRowIndex: PropTypes.number,
    items: PropTypes.array,
    loading: PropTypes.bool
}

export default DocumentCompany