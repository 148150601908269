import {
    ACTION_AUTH_USER,
    ACTION_TOKEN_VALIDATED,
    ACTION_USER_FETCHED,
    ACTION_USER_SIGNED_OUT
} from "./authActionTypes"

const userKey = 'starpro_user'

const initialState = {
    user: JSON.parse(localStorage.getItem(userKey)),
    valid: false,
    authenticating: false,
}

function authReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_USER_FETCHED:
            localStorage.setItem(userKey, JSON.stringify(action.payload))
            return { ...state, user: action.payload, valid: true }
        case ACTION_AUTH_USER:
            return { ...state, authenticating: action.payload }
        case ACTION_TOKEN_VALIDATED:
            return { ...state, valid: action.payload }
        case ACTION_USER_SIGNED_OUT:
            localStorage.removeItem(userKey)
            return { ...state, user: null, valid: false }
        default:
            return state
    }
}

export default authReducer