import React, {
    useEffect, 
    useMemo,
    useCallback,
    useState
} from 'react'

import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchDocuments,
    newRow,
    saveDocument,
    removeDocument,
    removeDocuments,
    setEditableRow,
} from './documentsActions'
import {
    fetchCompanies
} from '../companies/companiesActions'

import Table from '../common/Table/Table'

import {
    DATA_KEY_ID,
    DATA_KEY_COMPANY_ID,
    DATA_KEY_NAME,
    DATA_KEY_TYPE,
    DATA_KEY_ANNEX_ID,
    DATA_KEY_DATE,
    DATA_KEY_UNIT
} from './documentsConsts'

import { useSelection } from '../hooks/useSelection'

import DocumentId from './cells/documentId'
import DocumentCompany from './cells/documentCompany'
import DocumentName from './cells/documentName'
import DocumentType from './cells/documentType'
import DocumentFile from './cells/documentFile'
import DocumentActions from './cells/documentActions'

import { asyncValidate } from './asyncValidate'
import { shouldAsyncValidate } from '../form/shouldAsyncValidate'
import { isSuper } from '../auth/isSuper'

import TableHeader from '../tables/tableHeader'
import DocumentDate from './cells/documentDate'
import DocumentUnit from './cells/documentUnit'
import SelectionToggler from '../tables/selectionToggler'
import RowCheck from '../tables/rowCheck'
import { useParams } from 'react-router-dom'

import DeleteEntityDialog from '../tables/deleteEntityDialog'
import hasPermission from '../auth/hasPermission'

let DocumentsTableForm = props => {
    const {
        documents: { data, loading, editableRowIndex },
        companies: { data:companiesData, loading:loadingCompanies },
        auth: { user },
        handleSubmit,
        fetchDocuments,
        fetchCompanies,
        saveDocument,
        removeDocument,
        removeDocuments,
        newRow,
        setEditableRow,
        initialize
    } = props

    const { company_id }          = useParams()
    const [ sorting, setSorting ] = useState([])
    const [ file, setFile ]       = useState(null)
    const columnVisibility        = useMemo(() => !isSuper(user) ? { actions: false, 'Ação': false } : {}, []) // Esconde colunas administrativas
    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ productsToDelete, setProductsToDelete ] = useState([])


    const {
        selectedRows,
        clearSelections,
        toggleRowsSelection,
        selectRow
    } = useSelection([], data)

    // Exibe documentos e limpa seleções

    useEffect(() => {
        fetchDocuments(user, company_id)
        fetchCompanies(user)
        clearSelections()
        setEditableRow(-1)
        setFile(null)
    }, [
        fetchDocuments,
        fetchCompanies,
        clearSelections,
        setEditableRow, 
        setFile,
        user,
        company_id
    ])

    useEffect(() => {
        if (editableRowIndex !== -1)
            fetchCompanies(user)
    }, [ editableRowIndex, fetchCompanies, user ])

    useEffect(() => {
        initialize(data[editableRowIndex])
    }, [ initialize, data, editableRowIndex ])

    const openDeleteDialog = useCallback(documentsId => {
        setShowDeleteDialog(true)
        setProductsToDelete([ ...documentsId ])
    }, [ setProductsToDelete ])

    const closeDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
        setProductsToDelete([])
        clearSelections()
    }, [ setShowDeleteDialog, setProductsToDelete, clearSelections ])

    const handleDelete = useCallback(() => {
        setProductsToDelete(documentsId => {
            if (documentsId.length === 1)
                removeDocument(documentsId[0], user, company_id)
            else
                removeDocuments(documentsId, user, company_id)

            setShowDeleteDialog(false)

            return []
        })
    }, [
        setProductsToDelete,
        setShowDeleteDialog,
        removeDocuments,
        removeDocument,
        company_id,
        user
    ])

    const handleFile = useCallback(files => {
        setFile(files[0])
    }, [setFile])

    const onSubmit = useCallback(values => {
        saveDocument({ ...values, file }, user, company_id)
    }, [saveDocument, file, user, company_id])

    const renderHeaderComponent = useCallback(() => (
        <TableHeader
            user={user}
            selectedRows={selectedRows}
            loading={loading}
            editableRowIndex={editableRowIndex}
            onAdd={() => {
                newRow()
                clearSelections()
                setFile(null)
            } }
            onRemove={() => openDeleteDialog([ ...selectedRows ])} />
    ), [loading, selectedRows, editableRowIndex, newRow, user, company_id ])

    const renderSelectionToggler = useCallback(() => (
        <SelectionToggler
            checked={ selectedRows.length > 0 && selectedRows.length === data.length }
            disabled={ editableRowIndex !== -1 }
            value={ selectedRows.length > 0 && selectedRows.length === data.length ? 'true' : 'false' }
            onChange={toggleRowsSelection} />
    ), [toggleRowsSelection, selectedRows, editableRowIndex, data])

    const renderRowCheck = useCallback(info => {
        return (
            <RowCheck
                { ...info }
                editableRowIndex={editableRowIndex}
                selectedRows={selectedRows}
                onChange={ id => selectRow(id) } />
        )
    }, [editableRowIndex, selectedRows])

    const renderId = useCallback(info => (
        <DocumentId {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderCompany = useCallback(info => (
        <DocumentCompany
            { ...info }
            editableRowIndex={editableRowIndex}
            items={companiesData}
            loading={loadingCompanies} />
    ), [editableRowIndex, loadingCompanies, companiesData])

    const renderUnit = useCallback(info => (
        <DocumentUnit {...info} editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderDate = useCallback(info => (
        <DocumentDate {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])
    
    const renderName = useCallback(info => (
        <DocumentName { ...info } editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderType = useCallback(info => (
        <DocumentType { ...info } editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderDocumentFile = useCallback(info => (
        <DocumentFile
            { ...info }
            editableRowIndex={editableRowIndex}
            onChange={handleFile}
            file={file} />
    ), [ editableRowIndex, handleFile, file ])

    const renderActions = useCallback(info => (
        <DocumentActions
            {...info}
            editableRowIndex={editableRowIndex}
            selectedRows={selectedRows}
            waiting={loadingCompanies}
            onCancel={() => setEditableRow(-1)}
            onEdit={index => setEditableRow(index)}
            onRemove={ () => openDeleteDialog([ info.row.original.id]) } />
    ),
    [
        editableRowIndex,
        selectedRows,
        setEditableRow,
        loadingCompanies,
        removeDocument,
        user,
        company_id
    ])

    const columns = useMemo(() => ([
        {
            id: 'actions',
            header: renderSelectionToggler,
            cell: renderRowCheck
        },
        {
            header: 'Id',
            accessorKey: DATA_KEY_ID,
            cell: renderId
        },
        {
            header: 'Empresa',
            accessorKey: DATA_KEY_COMPANY_ID,
            cell: renderCompany
        },
        {
            header: 'Unidade',
            accessorKey: DATA_KEY_UNIT,
            cell: renderUnit
        },
        {
            header: 'Data',
            accessorKey: DATA_KEY_DATE,
            cell: renderDate
        },
        {
            header: 'Nome do Documento',
            accessorKey: DATA_KEY_NAME,
            cell: renderName
        },
        {
            header: 'Tipo de Documento',
            accessorKey: DATA_KEY_TYPE,
            cell: renderType
        },
        {
            header: 'Documento',
            accessorKey: DATA_KEY_ANNEX_ID,
            cell: renderDocumentFile
        },
        {
            header: 'Ação',
            cell: renderActions
        }
    ]), [
        renderSelectionToggler,
        renderRowCheck,
        renderId,
        renderCompany,
        renderUnit,
        renderDate,
        renderName,
        renderType,
        renderDocumentFile,
        renderActions
    ])

    const getRowProps = useCallback(({index}) => ({
        className: `${index === editableRowIndex ? 'active' : ''}`
    }), [ editableRowIndex ])

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table
                    data={data}
                    columns={columns}
                    loading={loading}
                    state={{
                        sorting
                    }}
                    initialState={{
                        columnVisibility
                    }}
                    getRowProps={getRowProps}
                    headerComponent={renderHeaderComponent} />
            </form>
            
            { isSuper(user)
                ? (
                    <DeleteEntityDialog
                    entityName={productsToDelete.length > 1 ? "Documentos" : "Documento"}
                    open={showDeleteDialog}
                    onClose={closeDeleteDialog}
                    onNegative={closeDeleteDialog}
                    onPositive={handleDelete} />
                ) : null
            }
            
        </div>
    )
}

DocumentsTableForm = reduxForm({
    form: 'document',
    asyncValidate,
    asyncChangeFields: [ 'file' ],
    shouldAsyncValidate
})(DocumentsTableForm)

DocumentsTableForm = hasPermission(DocumentsTableForm)

const mapStateToProps = state => ({
    auth: state.auth,
    documents: state.documents,
    companies: state.companies,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDocuments,
    fetchCompanies,
    removeDocument,
    removeDocuments,
    setEditableRow,
    newRow,
    saveDocument
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTableForm)