import { toast } from 'react-toastify'
import { reset } from 'redux-form'

import { apiClient } from '../config/api'
import { tokenExpired } from './tokenExpired'

import {
    ACTION_AUTH_HANDSHAKE,
    ACTION_AUTH_USER,
    ACTION_TOKEN_VALIDATED,
    ACTION_USER_FETCHED,
    ACTION_USER_SIGNED_OUT
} from './authActionTypes'


export function handshake() {
    return dispatch => {
        apiClient.get('/sanctum/csrf-cookie')
            .then(resp => {
                dispatch({
                    type: ACTION_AUTH_HANDSHAKE,
                    payload: { csrf: resp.data }
                })
            })
            .catch(() => {
                dispatch(logout())
            })
    }
}

// Realiza login

export function login({ email, password }) {
    return dispatch => {
        dispatch(authenticating(true))
        
        apiClient.post('/api/login', {
            email,
            password
        },
        {
            headers: {
                'Access-Control-Allow-Origin': 'true',
            }
        }).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: ACTION_USER_FETCHED, payload: resp.data })
            } else {
                toast.error('Credenciais incorretas.')
            }
            
        })
        .catch((error) => {
            if (error.response.status === 422) {
                toast.error('Credenciais incorretas.')
            } else {
                toast.error('Falha ao realizar login.')
            }
        })
        .finally(() => {
            dispatch(authenticating(false))
            dispatch(reset('auth'))
        })
    }
}

export function validateToken(user) {
    return dispatch => {
        dispatch(authenticating(true))

        apiClient.get(`/api/accounts/${user.account_id}/companies/1`)
            .then(() => { 
                dispatch({ type: ACTION_TOKEN_VALIDATED, payload: true })
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                }
            }).finally(() => dispatch(authenticating(false)))
    }
}

export function authenticating(val) {
    return { type: ACTION_AUTH_USER, payload: val }
}

export function getUser(userId) {
    return dispatch => {
        apiClient.get(`api/user/${userId}`)
            .then(resp => {
                if (resp.status === 200)
                    dispatch({ type: ACTION_USER_FETCHED, payload: { user: resp.data }})
                else
                    dispatch(logout())
            })
            .catch(() => {
                dispatch(logout())
            })
    }
}

// Realiza logout

export function logout() {
    return { type: ACTION_USER_SIGNED_OUT }
}