import React from 'react'
import PropTypes from 'prop-types'

import Cell from '../../common/Table/Cell'
import { Field } from 'redux-form'

import isVirtualRow from '../../tables/isVirtualRow'
import { DATA_KEY_ID } from '../schedulingConsts'

const SchedulingId = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={ !isVirtualRow(getValue()) ? getValue() : '' }
            renderPlain={ ({ plainText }) => <div className="disabled d-flex px-3 align-items-center w-100 h-100">{ plainText }</div>  }
            renderField={ ({ plainText }) => (
                <div className="disabled d-flex align-items-center w-100 h-100">
                    <Field
                        name={DATA_KEY_ID}
                        type="hidden"
                        component="input" />
                    <div className='px-3'>{ plainText }</div>
                </div>
            ) } />
    )
}

SchedulingId.propTypes = {
    editableRowIndex: PropTypes.number
}

export default SchedulingId