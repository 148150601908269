import React from 'react'

import PageSection from '../layout/PageSection/PageSection'
import CompaniesTableForm from './companiesTableForm'
import Translator from '../common/I18n/Translator'

const Companies = props => {
    return (
        <section className="Companies">
            <PageSection
                containerFluid
                title={ <Translator path="services.companies" /> }
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <CompaniesTableForm />
                    </div>
                </div>
            </PageSection>
        </section>
    )
}

export default Companies