import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import authReducer from '../auth/authReducer'
import contractsReducer from '../contracts/contractsReducer'
import documentsReducer from '../documents/documentsReducer'
import companiesReducer from '../companies/companiesReducer'
import schedulesReducer from '../scheduling/schedulingReducer'
import paymentsReducer from '../payment/paymentReducer'
import usersReducer from '../users/usersReducer'

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    contracts: contractsReducer,
    documents: documentsReducer,
    companies: companiesReducer,
    scheduling: schedulesReducer,
    payments: paymentsReducer,
    users: usersReducer
})

export default rootReducer