import React from 'react'

import PageSection from '../layout/PageSection/PageSection'
import AdminUsersTableForm from './adminUsersTableForm'

const AdminUsers = props => {
    return (
        <section className="AdminUsers">
            <PageSection
                containerFluid
                title="Usuários Administrativos"
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <AdminUsersTableForm />
                    </div>
                </div>
            </PageSection>
        </section>
    )
}

export default AdminUsers