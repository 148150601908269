import './App.css';
import MainTemplate from './template/main'
import { HashRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const App = () => (
    <Router>
      <div className="App">
        <MainTemplate />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>
    </Router>
)

export default App;
