export function normalizeNumber(value) {
    return value.replace(/\D/gi, '')
}

export function normalizeCnpj(value) {
    if (!value)
        return value

    const only14Digits = value.replace(/\D/g, '').substr(0, 14)

    return only14Digits.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
}

export function normalizeDate(value) {
    if (!value) return value;

    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .substr(0, 10)
}

export function normalizeCurrency(value) {
    if (!value) return value

    const onlyNums = value.replace(/\D/gi, '')
    let currency   = onlyNums
    currency = currency + '';
    currency = parseInt(currency.replace(/[\D]+/g, ''));
    currency = currency + '';
    currency = currency.replace(/([0-9]{2})$/g, ",$1");

    if (currency.length > 6) {
        currency = currency.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    return currency
}