import React, { useCallback, useState, useRef } from 'react'
import './PasswordField.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import TextField from '../TextField/TextField'

const PasswordField = props => {
    const ref                   = useRef(null)
    const [ hidden, setHidden ] = useState(true)

    // Mostra/esconde texto de entrada
    const toggleVisibility = useCallback(() => {
        setHidden(hidden => !hidden)

        if (ref && ref.current)
            ref.current.focus()
    }, [setHidden, ref])

    return (
        <div className="PasswordField">
            <TextField ref={ref} { ...props } input={ { ...props.input, type: hidden ? 'password' : 'text' } }  />
            <button
                type="button"
                onClick={toggleVisibility}
                disabled={props.disabled}>
                <FontAwesomeIcon icon={hidden ? faEye : faEyeSlash } color="#707070" size="1x" />
            </button>
        </div>
    )
}

export default PasswordField