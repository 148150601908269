import { apiClient } from "../config/api"
import { toast } from "react-toastify"
import {
    ACTION_CONTRACTS_FETCHED,
    ACTION_CONTRACTS_FETCHING,
    ACTION_TABLE_SET_ROW_EDITABLE,
    ACTION_CONTRACT_REMOVE,
    ACTION_TABLE_NEW_ROW
} from "./contractsActionTypes"
import { formatDate } from "../form/formatters"
import { parseDate } from "../form/parsers"
import { isSuper } from "../auth/isSuper"
import { tokenExpired } from "../auth/tokenExpired"

import { logout } from "../auth/authActions"

// Realiza consulta dos contratos

export function fetchContracts(user, companyId) {
    return dispatch => {
        dispatch(fetchingContracts(true))

        apiClient.get(`/api/accounts/${user.account_id}/companies/${companyId}/contracts`)
            .then(resp => {
                // Trata dados

                const data = resp.data.map(c => ({...c, date: formatDate(c.date) }), [])

                dispatch({ type: ACTION_CONTRACTS_FETCHED, payload: data })
                dispatch(fetchingContracts(false))
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Não foi possível consultar os contratos')
                }
            })
    }
}

export function saveContract(contract, user, companyId) {
    if (!isSuper(user)) {
        toast.error('Você não possui permissão para exclusão de contratos')

        return
    }

    // Trata dados

    const { id, status, date, unit, technical_manager, file } = contract
    const parsedDate = parseDate(date)

    const isLocalRow = id.toString().includes('ROW')

    return dispatch => {
        if (id && !isLocalRow) {
            apiClient.put(
                `/api/accounts/${user.account_id}/companies/${companyId}/contracts/${id}`,
                { status, date: parsedDate, unit, technical_manager, file },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Contrato atualizado com sucesso')
                dispatch(setEditableRow(-1))
                dispatch(fetchContracts(user, companyId))
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao atualizar contrato')
                }
            })
        } else {
            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/contracts`,
                { status, date: parsedDate, unit, technical_manager, file },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(() => {
                toast.success('Um novo contrato foi criado')
                dispatch(setEditableRow(-1))
                dispatch(fetchContracts(user, companyId))
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao criar novo contrato')
                }
            })
        }
    }
}

export function removeContract(contractId, user, companyId) {
    if (!isSuper(user)) {
        toast.error('Você não possui permissão para exclusão de contratos')

        return
    }

    return dispatch => {
        const isLocalRow = contractId.toString().includes('ROW')

        if (!isLocalRow) {
            apiClient.delete(
                `/api/accounts/${user.account_id}/companies/${companyId}/contracts/${contractId}`
            )
            .then(() => {
                toast.success('Contrato excluído')
                dispatch(fetchContracts(user, companyId))
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao remover contrato')
                }
            })

            
        } else {
            dispatch({ type: ACTION_CONTRACT_REMOVE, payload: contractId })
        }
    }
}

// Remove contratos

export function removeContracts(contractsId, user, companyId) {
    if (!isSuper(user)) {
        toast.error('Você não possui permissão para exclusão de contratos')

        return
    }

    return dispatch => {
        const requests = contractsId.map(contractId => {
            const isLocalRow = contractsId.toString().includes('ROW')

            return !isLocalRow
                ? (
                    apiClient.delete(
                        `/api/accounts/${user.account_id}/companies/${companyId}/contracts/${contractId}`
                    )
                )
                : (
                    dispatch({ type: ACTION_CONTRACT_REMOVE, payload: contractId })
                )
        })

        const allPromises = Promise.all(requests)

        allPromises.then(() => {
            if (contractsId.length === 1)
                toast.success('Contrato removido')
            else {
                toast.success('Contratos removidos')
            }

            dispatch(fetchContracts(user, companyId))
        }).catch(err => {
            if (tokenExpired(err)) { // Verifica se o token expirou
                dispatch(logout()) // Desloga usuário
                toast.error('Sessão expirada')
            } else {
                if (contractsId.length === 1) {
                    toast.error('Falha ao remover contrato')
                } else {
                    toast.error('Falha ao remover contratos')
                }
            }
        })
    }
}

// Controla estado das consultas

export function fetchingContracts(value) {
    return { type: ACTION_CONTRACTS_FETCHING, payload: value }
}

// Seta a linha que está sendo editada

export function setEditableRow(index) {
    return { type: ACTION_TABLE_SET_ROW_EDITABLE, payload: index }
}

export function newRow() {
    return dispatch => {
        dispatch({ type: ACTION_TABLE_NEW_ROW })
    }
}