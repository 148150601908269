import {
    faBarcode,
    faUsers,
    faRightFromBracket
} from "@fortawesome/free-solid-svg-icons"
import {
    faFileLines,
    faCalendarDays,
    faPaste,
    faBuilding
} from '@fortawesome/free-regular-svg-icons'

import Translator from "../common/I18n/Translator"

const services = [
    {
        title: <Translator path="services.companies" />,
        to: 'servicos/empresas',
        icon: faBuilding,
        _super: true
    },
    {
        title: <Translator path="services.users" />,
        to: 'servicos/usuarios',
        icon: faUsers,
        _super: true,
        admin: true
    },
    {
        title: <Translator path="services.payments" />,
        to: 'servicos/pagamentos',
        icon: faBarcode
    },
    {
        title: <Translator path="services.contracts" />,
        to: 'servicos/contratos',
        icon: faFileLines
    },
    {
        title: <Translator path="services.documents" />,
        to: 'servicos/documentacoes',
        icon: faPaste
    },
    {
        title: <Translator path="services.schedules" />,
        to: 'servicos/agendamentos',
        icon: faCalendarDays
    },
    {
        title: <Translator path="services.admin_users" />,
        to: 'servicos/usuarios-admnistrativos',
        icon: faUsers,
        _super: true
    },
    {
        title: <Translator path="services.sign_out" />,
        to: '/logout',
        icon: faRightFromBracket
    }
]

export default services