import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import './ActionButton.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ActionButton = props => {
    const { className, variant } = props

    const classes = useMemo(() => {
        let c = `${className || ''} ActionButton`

        switch (variant) {
            case 'primary':
                c += ' btnPrimary'
                break
            case 'secondary':
                c += ' btnSecondary'
                break
            case 'danger':
                c += ' btnDanger'
                break
            case 'warning':
                c += ' btnWarning'
                break
            case 'info':
                c += ' btnInfo'
                break
            case 'success':
                c += ' btnSuccess'
        }

        return c
    }, [className, variant])

    return (
        <button { ...props } className={classes}>
            <FontAwesomeIcon icon={props.icon} size="lg" color="#fff" />
        </button>
    )
}

ActionButton.propTypes = {
    icon: PropTypes.any,
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'danger',
        'warning',
        'success',
        'info'
    ])
}

ActionButton.defaultProps = {
    variant: 'primary'
}

export default ActionButton