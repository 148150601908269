import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { reduxForm, Field } from 'redux-form'

import TextField from '../common/TextField/TextField'
import Button from '../common/Button/Button'
import PasswordField from '../common/PasswordField/PasswordField'
import Feedback from '../common/Feedback/Feedback'
import Spinner from '../common/Spinner/Spinner'
import Translator from '../common/I18n/Translator'

function validate(values) {
    const errors = {}

    if (!values.email) {
        errors.email = 'Campo obrigatório'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'E-mail inválido'
    }

    if (!values.password) {
        errors.password = 'Campo obrigatório'
    }

    return errors
}


let LoginForm = props => {
    const { handleSubmit, isAuthenticating } = props

    const renderEmailField = useCallback(email => {
        const { meta: { touched, error } } = email

        return (
            <div className="formGroup">
                <TextField
                    {...email}
                    className={ `${email.className} ${touched && error ? 'isInvalid mb-2' : 'mb-4'}` }
                    disabled={isAuthenticating} />

                { touched && error && <Feedback className="mb-4" type="invalid">{error}</Feedback> }
            </div>
        )
    }, [isAuthenticating])

    const renderPasswordField = useCallback(password => {
        const { meta: { touched, error } } = password

        return (
            <div className="formGroup">
                <PasswordField
                    {...password}
                    className={ `${password.className} ${touched && error ? 'isInvalid mb-2' : 'mb-4'}` }
                    disabled={isAuthenticating} />

                { touched && error && <Feedback className="mb-4" type="invalid">{error}</Feedback> }
            </div>
        )
    }, [isAuthenticating])

    return (
        <div className="LoginForm" onSubmit={handleSubmit}>
            <h2 className="headingMd c-primary text-center mb-2"><Translator path="auth.heading" /></h2>
            <div className="textMd c-dark text-center"><Translator path="auth.form_title" /></div>

            <form className="mt-4">
                <Field
                    label={ <Translator path="auth.email_placeholder" /> }
                    name={"email"}
                    component={renderEmailField}
                    type="email" />
                
                <Field
                    label={ <Translator path="auth.password_placeholder" /> }
                    name="password"
                    component={renderPasswordField} />

                <a className="text-decoration-underline d-inline-block ml-2 mb-5 link-primary" href="#">
                    <Translator path="auth.forgot_password" />
                </a>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8">
                        <Button
                            className={
                                `d-flex
                                w-100
                                justify-content-center
                                textMd
                                text-weight-bold
                                `}
                            type="submit"
                            disabled={ isAuthenticating }
                            size="lg"
                            rounded="full">
                                { isAuthenticating && <Spinner className="me-2" size="2x" color="#fff" /> }
                                { isAuthenticating ? <Translator path="auth.signing_in"/> : <Translator path="auth.sign_in" /> }
                            </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

LoginForm = reduxForm({
    form: 'auth',
    validate
})(LoginForm)

LoginForm.propTypes = {
    isAuthenticating: PropTypes.bool
}

export default LoginForm