import React from 'react'
import PropTypes from 'prop-types'

import ActionButton from '../common/ActionButton/ActionButton'
import { faSave, faXmark, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons'

const RowActions = props => {
    const {
        edit,
        disabled,
        onSave,
        onCancel,
        onEdit,
        showRemove,
        onRemove
    } = props

    return (
        <div className="d-flex justify-content-center">
            {
                edit
                    ? (
                        <>
                            <ActionButton
                                className="me-1"
                                variant="success"
                                type="submit"
                                title="Salvar"
                                disabled={ disabled }
                                onClick={onSave}
                                icon={faSave} />
                            <ActionButton
                                variant="danger"
                                title="Cancelar"
                                type="button"
                                icon={faXmark}
                                onClick={onCancel} />
                        </>
                    )
                    : (
                        <>
                            <ActionButton
                                className="me-1"
                                variant="warning"
                                title="Editar"
                                type="button"
                                disabled={ disabled }
                                icon={faPencil}
                                onClick={onEdit}  />
                            {
                                showRemove
                                    &&  <ActionButton
                                            title="Excluir"
                                            type="button"
                                            variant="danger"
                                            disabled={ disabled }
                                            icon={faTrashCan}
                                            onClick={onRemove} />
                            }
                        </>
                    )
            }
            
        </div>
    )
}

RowActions.propTypes = {
    edit: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    showRemove: PropTypes.bool,
    onRemove: PropTypes.func
}

RowActions.defaultProps = {
    showRemove: PropTypes.bool
}

export default RowActions