import axios from 'axios'
import { isSuper } from '../auth/isSuper'

export const BASER_URL = 'https://starpro-api.helpreforcoescolar.com.br'

export const apiClient = axios.create({
    baseURL: BASER_URL,
    headers: {
        'Access-Control-Allow-Origin': 'true',
        'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true
})

export function buildEndpointURL(entityName, user) {
    return isSuper(user)
        ? `/api/accounts/${user.account_id}/${entityName}`
        : `/api/accounts/${user.account_id}/companies/${user.company_id}/${entityName}`
}
