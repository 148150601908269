import React from 'react'
import Header from '../layout/Header/Header'
import Footer from '../layout/Footer/Footer'
import Routes from '../config/routes'

const MainTemplate = props => (
    <div className="MainTemplate">
        <Header />
        <main>
            <Routes />
        </main>
        <Footer />
    </div>
    
)

export default MainTemplate