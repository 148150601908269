import React, { useCallback } from 'react'
import './BackButton.css'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateBack } from '@fortawesome/free-solid-svg-icons'

import Translator from '../I18n/Translator'

const BackButton = props => {
    const navigate = useNavigate()

    const goToPreviousPage = useCallback(() => {
        navigate('/')
    }, [navigate])

    return (
        <div className={`${props.className || ''} BackButton`}>
            <button to={props.to} className="buttonIcon" onClick={goToPreviousPage}>
                <FontAwesomeIcon icon={faArrowRotateBack} size="lg" color="#000" />
            </button>
            <span className="buttonTitle">
                <Translator path="services.go_back" />
            </span>
        </div>
    )
}

BackButton.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    to: PropTypes.string
}

export default BackButton