import React, {
    useEffect, 
    useMemo,
    useCallback,
    useState
} from 'react'

import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchSchedules,
    newRow,
    saveScheduling,
    removeScheduling,
    removeSchedules,
    setEditableRow,
} from './schedulingActions'
import {
    fetchCompanies
} from '../companies/companiesActions'

import Table from '../common/Table/Table'

import {
    DATA_KEY_ID,
    DATA_KEY_COMPANY_ID,
    DATA_KEY_ANNEX_ID,
    DATA_KEY_DATE,
    DATA_KEY_UNIT,
    DATA_KEY_REQUEST_DATE,
    DATA_KEY_SERVICE_TYPE,
    DATA_KEY_TECHNICAL_MANAGER,
    DATA_KEY_STATUS
} from './schedulingConsts'

import { useSelection } from '../hooks/useSelection'

import SchedulingId from './cells/schedulingId'
import SchedulingCompany from './cells/schedulingCompany'
import SchedulingDate from './cells/schedulingDate'
import SchedulingRequestDate from './cells/schedulingRequestDate'
import SchedulingServiceType from './cells/schedulingServiceType'
import SchedulingStatus from './cells/schedulingStatus'
import SchedulingUnit from './cells/schedulingUnit'
import SchedulingTechnicalManager from './cells/schedulingTechnicalManager'
import SchedulingFile from './cells/schedulingFile'
import SchedulingActions from './cells/schedulingActions'

import { asyncValidate } from './asyncValidate'
import { shouldAsyncValidate } from '../form/shouldAsyncValidate'
import { isSuper } from '../auth/isSuper'

import TableHeader from '../tables/tableHeader'
import SelectionToggler from '../tables/selectionToggler'
import RowCheck from '../tables/rowCheck'
import { useParams } from 'react-router-dom'
import hasPermission from '../auth/hasPermission'

let SchedulingTableForm = props => {
    const {
        scheduling: { data, loading, editableRowIndex },
        companies: { data:companiesData, loading:loadingCompanies },
        auth: { user },
        handleSubmit,
        fetchSchedules,
        fetchCompanies,
        saveScheduling,
        removeScheduling,
        removeSchedules,
        newRow,
        setEditableRow,
        initialize
    } = props

    const { company_id }          = useParams()
    const [ sorting, setSorting ] = useState([])
    const [ file, setFile ]       = useState(null)
    const columnVisibility        = useMemo(() => !isSuper(user) ? { actions: false, 'Ação': false } : {}, []) // Esconde colunas administrativas

    const {
        selectedRows,
        clearSelections,
        toggleRowsSelection,
        selectRow
    } = useSelection([], data)

    // Exibe documentos e reseta informações

    useEffect(() => {
        fetchSchedules(user, company_id)
        fetchCompanies(user)
        setEditableRow(-1)
        setFile(null)
    }, [
        user,
        company_id
    ])

    useEffect(() => {
        clearSelections() // Limpa seleções ao detectar alteração nos dados
    }, [ data, clearSelections ])

    useEffect(() => {
        if (editableRowIndex !== -1)
            fetchCompanies(user)
    }, [ editableRowIndex, fetchCompanies, user ])

    useEffect(() => {
        initialize(data[editableRowIndex])
    }, [ initialize, data, editableRowIndex ])

    const handleFile = useCallback(files => {
        setFile(files[0])
    }, [setFile])

    const onSubmit = useCallback(values => {
        saveScheduling({ ...values, file }, user, company_id)
    }, [saveScheduling, file, user, company_id])

    const renderHeaderComponent = useCallback(() => (
        <TableHeader
            user={user}
            selectedRows={selectedRows}
            loading={loading}
            editableRowIndex={editableRowIndex}
            onAdd={() => {
                newRow()
                setFile(null)
            } }
            onRemove={() => {
                removeSchedules(selectedRows, user, company_id)
            }} />
    ), [loading, selectedRows, editableRowIndex, newRow, user, company_id ])

    const renderSelectionToggler = useCallback(() => (
        <SelectionToggler
            checked={ selectedRows.length > 0 && selectedRows.length === data.length }
            disabled={ editableRowIndex !== -1 }
            value={ selectedRows.length > 0 && selectedRows.length === data.length ? 'true' : 'false' }
            onChange={toggleRowsSelection} />
    ), [toggleRowsSelection, selectedRows, editableRowIndex, data])

    const renderRowCheck = useCallback(info => {
        return (
            <RowCheck
                { ...info }
                editableRowIndex={editableRowIndex}
                selectedRows={selectedRows}
                onChange={ id => selectRow(id) } />
        )
    }, [editableRowIndex, selectedRows])

    const renderId = useCallback(info => (
        <SchedulingId {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderCompany = useCallback(info => (
        <SchedulingCompany
            { ...info }
            editableRowIndex={editableRowIndex}
            items={companiesData}
            loading={loadingCompanies} />
    ), [editableRowIndex, loadingCompanies, companiesData])

    const renderUnit = useCallback(info => (
        <SchedulingUnit {...info} editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderDate = useCallback(info => (
        <SchedulingDate {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderRequestDate = useCallback(info => (
        <SchedulingRequestDate {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderServiceType = useCallback(info => (
        <SchedulingServiceType { ...info } editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderTechnicalManager = useCallback(info => (
        <SchedulingTechnicalManager { ...info } editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderStatus = useCallback(info => (
        <SchedulingStatus { ...info } editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderSchedulingFile = useCallback(info => (
        <SchedulingFile
            { ...info }
            editableRowIndex={editableRowIndex}
            onChange={handleFile}
            file={file} />
    ), [ editableRowIndex, handleFile, file ])

    const renderActions = useCallback(info => (
        <SchedulingActions
            {...info}
            editableRowIndex={editableRowIndex}
            selectedRows={selectedRows}
            waiting={loadingCompanies}
            onCancel={() => setEditableRow(-1)}
            onEdit={index => setEditableRow(index)}
            onRemove={ id => {
                removeScheduling(id, user, company_id)
            } } />
    ),
    [
        editableRowIndex,
        selectedRows,
        setEditableRow,
        loadingCompanies,
        removeScheduling,
        user,
        company_id
    ])

    const columns = useMemo(() => ([
        {
            id: 'actions',
            header: renderSelectionToggler,
            cell: renderRowCheck
        },
        {
            header: 'Id',
            accessorKey: DATA_KEY_ID,
            cell: renderId
        },
        {
            header: 'Empresa',
            accessorKey: DATA_KEY_COMPANY_ID,
            cell: renderCompany
        },
        {
            header: 'Unidade',
            accessorKey: DATA_KEY_UNIT,
            cell: renderUnit
        },
        {
            header: 'Data da solicitação',
            accessorKey: DATA_KEY_REQUEST_DATE,
            cell: renderRequestDate
        },
        {
            header: 'Data do agendamento',
            accessorKey: DATA_KEY_DATE,
            cell: renderDate
        },
        {
            header: 'Tipo de serviço',
            accessorKey: DATA_KEY_SERVICE_TYPE,
            cell: renderServiceType
        },
        {
            header: 'Responsável técnico',
            accessorKey: DATA_KEY_TECHNICAL_MANAGER,
            cell: renderTechnicalManager
        },
        {
            header: 'Status',
            accessorKey: DATA_KEY_STATUS,
            cell: renderStatus
        },
        {
            header: 'Relatório',
            accessorKey: DATA_KEY_ANNEX_ID,
            cell: renderSchedulingFile
        },
        {
            header: 'Ação',
            cell: renderActions
        }
    ]), [
        renderSelectionToggler,
        renderRowCheck,
        renderId,
        renderCompany,
        renderUnit,
        renderRequestDate,
        renderDate,
        renderServiceType,
        renderSchedulingFile,
        renderTechnicalManager,
        renderStatus,
        renderActions
    ])

    const getRowProps = useCallback(({index}) => ({
        className: `${index === editableRowIndex ? 'active' : ''}`
    }), [ editableRowIndex ])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Table
                data={data}
                columns={columns}
                loading={loading}
                state={{
                    sorting
                }}
                initialState={{
                    columnVisibility
                }}
                getRowProps={getRowProps}
                headerComponent={renderHeaderComponent} />
        </form>
    )
}

SchedulingTableForm = reduxForm({
    form: 'scheduling',
    asyncValidate,
    asyncChangeFields: [ 'file' ],
    shouldAsyncValidate
})(SchedulingTableForm)

SchedulingTableForm = hasPermission(SchedulingTableForm)

const mapStateToProps = state => ({
    auth: state.auth,
    scheduling: state.scheduling,
    companies: state.companies,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchSchedules,
    fetchCompanies,
    removeScheduling,
    removeSchedules,
    setEditableRow,
    newRow,
    saveScheduling
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(SchedulingTableForm)