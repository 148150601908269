import {
    ACTION_TYPE_PAYMENTS_FETCHED,
    ACTION_TYPE_PAYMENTS_FETCHING,
    ACTION_TYPE_PAYMENTS_REMOVED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE
} from "./paymentActionTypes"

const initialValues = {
    data: [],
    loading: false,
    editableRowIndex: -1
}

function paymentReducer(state = initialValues, action) {
    switch (action.type) {
        case ACTION_TYPE_PAYMENTS_FETCHED:
            return { ...state, data: [ ...action.payload ] }
        case ACTION_TYPE_PAYMENTS_FETCHING:
            return { ...state, loading: action.payload }
        case ACTION_TYPE_TABLE_NEW_ROW:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: `ROW_${Date.now()}`,
                        company_id: '',
                        unit: '',
                        service_date: '',
                        status: '',
                        due_date: '',
                        billet_code: '',
                        billet_annex_id: null,
                        invoice_annex_id: null
                    }
                ],
                editableRowIndex: state.data.length
            }
        case ACTION_TYPE_PAYMENTS_REMOVED:
            const data = [ ...state.data ]

            let indexOf = data.findIndex(c => c.id === action.payload)
            if (indexOf !== -1) {
                data.splice(indexOf, 1)
            }

            return { ...state, data }
        case ACTION_TYPE_TABLE_SET_ROW_EDITABLE:
            return  { ...state, editableRowIndex: action.payload }
        default:
            return state
    }
}

export default paymentReducer