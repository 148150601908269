import { toast } from 'react-toastify'
import { apiClient } from '../config/api'
import {
    ACTION_TYPE_USERS_FETCHED,
    ACTION_TYPE_USERS_FETCHING,
    ACTION_TYPE_USERS_REMOVED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE
} from './usersActionTypes'

import isVirtualRow from '../tables/isVirtualRow'
import { tokenExpired } from '../auth/tokenExpired'
import { logout } from '../auth/authActions'

export function fetchUsers(user) {
    const endpoint = `/api/accounts/${user.account_id}/users`

    return dispatch => {
        dispatch(fetchingUsers(true))

        apiClient.get(endpoint)
            .then(resp => {
                dispatch({ type: ACTION_TYPE_USERS_FETCHED, payload: resp.data })
            })
            .catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao consultar usuários')
                }
            })
            .finally(() => dispatch(fetchingUsers(false)))
    }
}

export function fetchingUsers(value) {
    return { type: ACTION_TYPE_USERS_FETCHING, payload: value }
}

export function saveUser(newUser, user) {
    const {
        id,
        name,
        email,
        password,
        company_id,
     } = newUser

    const endpoint = `/api/accounts/${user.account_id}/users`

    return dispatch => {
        if (id && !isVirtualRow(id)) {
            // Atualiza

            apiClient.put(
                `${endpoint}/${id}`,
                { name, email, password, company_id },
            ).then(() => {
                toast.success('Usuário atualizado')
                dispatch(setEditableRow(-1))
                dispatch(fetchUsers(user))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao atualizar usuário')
                }
            })
        } else {
            // Cria

            apiClient.post(
                endpoint,
                { name, email, password, company_id },
            ).then(() => {
                toast.success('Usuário criado')
                dispatch(setEditableRow(-1))
                dispatch(fetchUsers(user))
            }).catch(() => toast.error('Falha ao criar usuário'))
        }
    }
}

// Remove usuário

export function removeUser(userId, user) {
    if (userId === user.id) {
        toast.error('Um usuário não pode excluir a si mesmo.')
        
        return
    }

    return dispatch => {
        const endpoint = `/api/accounts/${user.account_id}/users`

        if (!isVirtualRow(userId)) {
            apiClient.delete(`${endpoint}/${userId}`)
                .then(() => {
                    toast.success('Usuário excluído')
                    dispatch(fetchUsers(user))
                })
                .catch(err => {
                    if (tokenExpired(err)) { // Verifica se o token expirou
                        dispatch(logout()) // Desloga usuário
                        toast.error('Sessão expirada')
                    } else {
                        toast.error('Falha ao excluir usuário')
                    }
                })
        } else {
            toast.success('Usuário excluído')
            dispatch({ type: ACTION_TYPE_USERS_REMOVED, payload: userId })
        }
    }
}

// Remove usuários

export function removeUsers(usersId, user) {
    if (usersId.includes(user.id)) {
        toast.error('Um usuário não pode excluir a si mesmo.')

        return
    }

    return dispatch => {
        const endpoint = `/api/accounts/${user.account_id}/users`

        const requests = usersId.map(userId => {
            return !isVirtualRow(userId)
                ? (
                    apiClient.delete(
                        `${endpoint}/${userId}`
                    )
                )
                : (
                    dispatch({ type: ACTION_TYPE_USERS_REMOVED, payload: userId })
                )
        })

        const allPromises = Promise.all(requests)

        allPromises.then(() => {
            if (usersId.length === 1)
                toast.success('Usuário excluído')
            else {
                toast.success(`${usersId.length} usuários excluídos`)
            }

            dispatch(fetchUsers(user.account_id, 1))
        }).catch(err => {
            if (tokenExpired(err)) { // Verifica se o token expirou
                dispatch(logout()) // Desloga usuário
                toast.error('Sessão expirada')
            } else {
                if (usersId.length === 1) {
                    toast.error('Falha ao excluir usuário')
                } else {
                    toast.error('Falha ao excluir usuários')
                }
            }
        })
    }
}

// Seta a linha que está sendo editada

export function setEditableRow(index) {
    return { type: ACTION_TYPE_TABLE_SET_ROW_EDITABLE, payload: index }
}

export function newRow() {
    return dispatch => {
        dispatch({ type: ACTION_TYPE_TABLE_NEW_ROW })
    }
}