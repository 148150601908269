import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'
import CellField from '../../common/Table/CellField'

import { DATA_KEY_CNPJ } from '../companiesConsts'
import { isCnpj, required } from '../../form/validators'
import { normalizeCnpj } from '../../form/normalizers'
import { formatCnpj } from '../../form/formatters'

const CompanyCnpj = props => {
    const { getValue, row: { index }, editableRowIndex } = props
        
    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={formatCnpj(getValue())}
            renderField={ () => (
                <Field
                    name={DATA_KEY_CNPJ}
                    component={CellField}
                    validate={[ required, isCnpj ]}
                    normalize={normalizeCnpj}
                    />
            ) } />
    )
}

CompanyCnpj.propTypes = {
    editableRowIndex: PropTypes.number   
}

export default CompanyCnpj