export const DATA_KEY_ID                        =  'id'
export const DATA_KEY_UNIT                      = 'unit'
export const DATA_KEY_SERVICE_DATE              = 'service_date'
export const DATA_KEY_DUE_DATE                  = 'due_date'
export const DATA_KEY_STATUS                    = 'status'
export const DATA_KEY_BILLET_CODE               = 'billet_code'
export const DATA_KEY_BILLET_ANNEX_ID           = 'billet_annex_id'
export const DATA_KEY_INVOICE_ANNEX_ID          = 'invoice_annex_id'
export const DATA_KEY_BILLET_FILE               = 'billet_file'
export const DATA_KEY_INVOICE_FILE              = 'invoice_file'
export const DATA_KEY_COMPANY_ID                = 'company_id'
export const DATA_KEY_VALUE                     = 'value'