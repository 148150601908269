import { logout } from "./authActions"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Logout = props => {
    const navigate = useNavigate()

    useEffect(() => {
        props.logout()
        navigate('/auth')
    }, [ props.logout ])

    return null
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout
}, dispatch)

export default connect(null, mapDispatchToProps)(Logout)