import { APPLICATION_PDF } from '../files/mimeTypes'
import { isFileMime } from '../form/validators'
import { DATA_KEY_BILLET_FILE, DATA_KEY_INVOICE_FILE } from './paymentConsts'

export const asyncValidate = values => {

    if (values[DATA_KEY_BILLET_FILE]) {
        return isFileMime(values[DATA_KEY_BILLET_FILE], [APPLICATION_PDF])
            .then(null, error => {
                throw { [DATA_KEY_BILLET_FILE]: error }
            })
    }

    if (values[DATA_KEY_INVOICE_FILE]) {
        return isFileMime(values[DATA_KEY_INVOICE_FILE], [APPLICATION_PDF])
            .then(null, error => {
                throw { [DATA_KEY_INVOICE_FILE]: error }
            })
    }

    return Promise.resolve()
}