import React from 'react'
import './companySelectorItem.css'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { formatCnpj } from '../form/formatters'
import { useMemo } from 'react'

const CompanySelectorItem = ({ data: { id, name, cnpj }, className, route }) => {
    const redirectUrl = useMemo(() => `/${id}/${route}`, [ route ])
    
    return (
        <Link className={`${ className || '' } CompanySelectorItem link-primary`} to={redirectUrl}>
            <span className="companyName">{name}</span>
            <span className="companyCnpj">{formatCnpj(cnpj)}</span>
        </Link>
    )
}

CompanySelectorItem.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        name: PropTypes.string,
        cnpj: PropTypes.string
    }).isRequired
}

export default CompanySelectorItem