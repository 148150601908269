import { toast } from "react-toastify"
import { apiClient } from "../config/api"
import {
    ACTION_TYPE_PAYMENTS_FETCHING,
    ACTION_TYPE_PAYMENTS_FETCHED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE,
    ACTION_TYPE_PAYMENTS_REMOVED
} from "./paymentActionTypes"

import { logout } from "../auth/authActions"
import { tokenExpired } from "../auth/tokenExpired"

import isVirtualRow from '../tables/isVirtualRow'

import { formatDate } from "../form/formatters"
import { parseCurrency, parseDate } from "../form/parsers"
import { sortPaymentsByStatusDueDate } from "./sortPaymentsByStatusDueDate"

export function fetchPayments(user, companyId) {
    return dispatch => {
        dispatch(fetchingPayments(true))

        apiClient.get(`/api/accounts/${user.account_id}/companies/${companyId}/payments`)
            .then(resp => {
                // Trata dados

                const data = resp.data.map(s => ({
                    ...s,
                    service_date: formatDate(s.service_date),
                    due_date: formatDate(s.due_date)
                }), [])

                const sortedPayments = sortPaymentsByStatusDueDate(data)

                dispatch({ type: ACTION_TYPE_PAYMENTS_FETCHED, payload: sortedPayments })
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao consultar os pagamentos')
                }
            })
            .finally(() => dispatch(fetchingPayments(false)))
    }
}

export function fetchingPayments(value) {
    return { type: ACTION_TYPE_PAYMENTS_FETCHING, payload: value }
}

export function savePayment(payment, user, companyId) {
    const {
        id,
        company_id,
        unit,
        service_date,
        status,
        due_date,
        billet_code,
        billet_file,
        invoice_file,
        value
    } = payment
    const parsedServiceDate        = parseDate(service_date)
    const parsedDueDate            = parseDate(due_date)
    const parsedValue              = parseCurrency(value)

    return dispatch => {
        if (id && !isVirtualRow(id)) {
            // Atualiza

            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/payments/${id}`,
                {
                    company_id,
                    service_date: parsedServiceDate,
                    due_date: parsedDueDate,
                    billet_code,
                    billet_file,
                    status,
                    invoice_file,
                    unit,
                    value: parsedValue,
                    _method: 'put'
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Pagamento atualizado')
                dispatch(setEditableRow(-1))
                dispatch(fetchPayments(user, companyId))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao atualizar pagamento')
                }
            })
        } else {
            // Cria

            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/payments`,
                {
                    id,
                    company_id,
                    unit,
                    service_date: parsedServiceDate,
                    due_date: parsedDueDate,
                    status,
                    billet_code,
                    billet_file,
                    invoice_file,
                    value: parsedValue
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Pagamento criado')
                dispatch(setEditableRow(-1))
                dispatch(fetchPayments(user, companyId))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao criar pagamento')
                }
            })
        }
    }
}

// Remove paymento

export function removePayment(paymentId, user, companyId) {
    return dispatch => {
        if (!isVirtualRow(paymentId)) {
            apiClient.delete(`/api/accounts/${user.account_id}/companies/${companyId}/payments/${paymentId}`)
                .then(() => {
                    toast.success('Pagamento excluído')
                    dispatch(fetchPayments(user, companyId))
                })
                .catch(err => {
                    if (tokenExpired(err)) { // Verifica se o token expirou
                        dispatch(logout()) // Desloga usuário
                        toast.error('Sessão expirada')
                    } else {
                        toast.error('Falha ao excluir pagamento')
                    }
                })
        } else {
            toast.success('Pagamento excluído')
            dispatch({ type: ACTION_TYPE_PAYMENTS_REMOVED, payload: paymentId })
        }
    }
}

// Remove paymentos

export function removePayments(paymentsId, user, companyId) {
    return dispatch => {
        const requests = paymentsId.map(paymentId => {
            return !isVirtualRow(paymentId)
                ? (
                    apiClient.delete(
                        `/api/accounts/${user.account_id}/companies/${companyId}/payments/${paymentId}`
                    )
                )
                : (
                    dispatch({ type: ACTION_TYPE_PAYMENTS_REMOVED, payload: paymentId })
                )
        })

        const allPromises = Promise.all(requests)

        allPromises.then(() => {
            if (paymentsId.length === 1)
                toast.success('Pagamento excluído')
            else {
                toast.success(`${paymentsId.length} pagamentos excluídos`)
            }

            dispatch(fetchPayments(user, companyId))
        }).catch(err => {
            if (tokenExpired(err)) { // Verifica se o token expirou
                dispatch(logout()) // Desloga usuário
                toast.error('Sessão expirada')
            } else {
                if (paymentsId.length === 1) {
                    toast.error('Falha ao excluir pagamento')
                } else {
                    toast.error('Falha ao excluir pagamento')
                }
            }
        })
    }
}

// Seta a linha que está sendo editada

export function setEditableRow(index) {
    return { type: ACTION_TYPE_TABLE_SET_ROW_EDITABLE, payload: index }
}

export function newRow() {
    return { type: ACTION_TYPE_TABLE_NEW_ROW }
}