import React, { useCallback, useState, useEffect } from 'react'
import './Header.css'
import PropTypes from 'prop-types'

import ButtonLink from '../../common/ButtonLink/ButtonLink'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import NavItem from './NavItem'
import HamburguerButton from '../../common/HamburguerButton/HamburguerButton'
import SideNav from './SideNav'
import SideNavItem from './SideNavItem'
import UserProfile from '../../auth/userProfile'
import { I18n } from '../../common/I18n'
import Translator from '../../common/I18n/Translator'

import { useScroll } from '../../hooks/useScroll'
import { useLocation } from 'react-router-dom'
import { usePrevious } from '../../hooks/usePrevious'

import { useResize } from '../../hooks/useResize'
import { sanitizeUrl } from './urlUtils'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../../auth/authActions'

const Header = props => {
    const location                        = useLocation()
    const { y:scrollY }                   = useScroll()
    const { width:innerWidth }            = useResize()
    const previousY                       = usePrevious(scrollY)
    const [ visible, setVisible ] = useState(true)
    const [ showSideNav, setShowSideNav ] = useState(false)
    const { auth: { user }, logout } = props
    
    // Trata barra de rolagem

    useEffect(() => {
        const MIN_Y = 100

        if (scrollY > MIN_Y) {
            if (scrollY >= previousY) {
                setVisible(false)
            } else {
                setVisible(true)
            }
        } else {
            setVisible(true)
        }
    }, [scrollY, setVisible])

    // Trata redimensionamento da janela

    useEffect(() => {
        const BREAKPOINT = 1199.8
        
        if (innerWidth >= BREAKPOINT)
            setShowSideNav(false)
    }, [innerWidth, setShowSideNav])

    const [ items ] = useState([
        {
            title: <Translator path="header.home" />,
            url: "/"
        },
        {
            title: <Translator path="header.about_starpro" />,
            url: 'https://starproambiental.com.br/#/a-starpro',
            anchor: true,
            target: '_blank'
        },
        {
            title: <Translator path="header.contact_us" />,
            url: 'https://starproambiental.com.br/#/#contate_nos',
            anchor: true,
            target: '_blank'
        }
    ])

    const renderItem = useCallback((item, i) => {
        const sanitizedUrl     = sanitizeUrl(item.url)
        const santizedLocation = sanitizeUrl(location.pathname)
        const activeIndex      = (item.items || []).findIndex(val => {
            let sanitizedSubitemUrl = sanitizeUrl(val.url)

            return sanitizedSubitemUrl === santizedLocation
        })

        return (
            <NavItem
                key={`navItem${i}`}
                {...item}
                active={ sanitizedUrl === santizedLocation }
                activeIndex={activeIndex} />
        )
    }, [location.pathname])

    const renderSideNavItem = useCallback((item, i) => {
        const sanitizedUrl     = sanitizeUrl(item.url)
        const santizedLocation = sanitizeUrl(location.pathname)
        const activeIndex      = (item.items || []).findIndex(val => {
            let sanitizedSubitemUrl = sanitizeUrl(val.url)

            return sanitizedSubitemUrl === santizedLocation
        })

        return (
            <SideNavItem
                key={`navItem${i}`}
                {...item}
                active={ sanitizedUrl === santizedLocation }
                activeIndex={activeIndex}
                onClick={() => setShowSideNav(false)} />
        )
    }, [location.pathname])

    return (
        <header className={`Header ${props.fixed ? 'fixed' : ''} ${!visible ? 'headerHidden' : '' }`}>
            <div className="container">
                <Link className="brand" to="/">
                    <img src={`${process.env.PUBLIC_URL}/images/logotipo_colorido.png`} alt="Logotipo Colorido Starpro" />
                </Link>
                <div className="navbar">
                    <Navbar id="menu">
                        { items.map(renderItem) }
                    </Navbar>
                    <ButtonLink className="btnLogin me-5"><Translator path="header.customers_area" /></ButtonLink>
                    <HamburguerButton
                        className="ml-3 me-3"
                        open={showSideNav}
                        onClick={() => setShowSideNav(val => !val)} />
                    {
                        user && <UserProfile user={user} onLogout={logout} />
                    }
                </div>
            </div>
            <SideNav visible={showSideNav}>
                { items.map(renderSideNavItem) }
            </SideNav>
        </header>
    )
}

Header.propTypes = {
    fixed: PropTypes.bool,
}

const mapStateToProps = state => ({
    auth: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    logout
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)