import { useEffect } from "react"
import PropTypes from 'prop-types'
import './withAuth.css'

import PageNotFound from "../404/pageNotFound"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { validateToken } from "./authActions"
import { useNavigate } from "react-router-dom"
import { isAdmin } from "./isAdmin"
import Spinner from "../common/Spinner/Spinner"
import { isSuper } from "./isSuper"

const WithAuth = (props) => {
    const navigate = useNavigate()
    const { auth: { user, authenticating }, admin, _super, validateToken } = props

    useEffect(() => {
        if (user) {
            validateToken(user) // Verifica se o token ainda é válido
        } else {
            navigate('/auth')
        }
    }, [ user, navigate ])

    if (authenticating) {
        return (
            <div className="tokenSpinner d-flex h-100 w-100 justify-content-center align-items-center">
                <Spinner color="var(--primary-color)" size="3x" />
            </div>
        )
    } else {
        if (user) {

            if (_super && !admin) {
                if (!isSuper(user))
                    return <PageNotFound />
            } else if (!_super && admin) {
                if (!isAdmin(user))
                    return <PageNotFound />
            } else if (_super && admin) {
                if (!isSuper(user) && !isAdmin(user))
                    return <PageNotFound />
            }
            
            return props.children
        }
    }

    return null
}

WithAuth.propTypes = {
    _super: PropTypes.bool,
    admin: PropTypes.bool
}

const mapStateToProps = state => ({
    auth: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    validateToken
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth)

