export function isDate(value) {
    let dateRegExp = /^[0-9]{2}\/[0-9]{2}\/([0-9]{2}|[0-9]{4})$/

    if (dateRegExp.test(value)) {
        const date  = value.split('/')
        const day   = parseInt(date[0], 10)
        const month = parseInt(date[1], 10)
        const year  = parseInt(date[2], 10)

        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return 'Data inválida'
        }

        const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29
        }

        return day > 0 && day <= monthLength[month - 1] ? undefined : 'Data inválida'
    } else {
        return 'Data inválida'
    }
}

export function isCnpj(value) {
    const onlyNums = value.replace(/\D/g, '')

    return onlyNums.length === 14 ? undefined : 'CNPJ inválido'
}

export function required(value) {
    return value ? undefined : 'Campo obrigatório*'
}

export function isIn(value, values) {
    return values.includes(value) ? undefined : 'Valor inválido'
}

// Valida e-mail

export function isEmail(value) {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    return emailRegex.test(value) ? undefined : 'E-mail inválido'
}

// Arquivo obrigatório

export function fileRequired(files) {
    return (files && files.length > 0) ? undefined : 'Selecione um arquivo'
}

// Valida tamanho do arquivo

export function fileMaxSize(files, maxSize = 20000000) {
    let valid = true

    for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file.size > maxSize) {
            valid = false
            break
        }
    }

    return valid ? undefined : `Tamanho execedido > ${(maxSize / 1000000).toFixed(2)}mb`
}

// Valida tipo do arquivo

export function isFileMime(files, mimeTypes) {
    if (!files)
        return

    const promises = []

    for (const blob of files) {
        promises.push(new Promise((resolve, reject) => {
            const fileReader = new FileReader()

            fileReader.onloadend = e => {
                if (e.target.readyState === FileReader.DONE) {
                    const arr = (new Uint8Array(e.target.result)).subarray(0, 4)
                    let header = ''
        
                    for (let i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16)
                    }
                    
                    if (!mimeTypes.includes(header)) {
                        reject('Formato inválido')
                    } else {
                        resolve()
                    }
                }
            }

            fileReader.readAsArrayBuffer(blob)
        }))
    }

    return Promise.all(promises)
}