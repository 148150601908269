import React from 'react'
import './logoutButton.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import Translator from '../common/I18n/Translator'

const LogoutButton = props => (
    <button { ...props } className="LogoutButton">
        <FontAwesomeIcon className="logoutIcon" icon={faRightFromBracket} color="#000" size="1x" />
        <span><Translator path="services.sign_out" /></span>
    </button>
)

export default LogoutButton