import { useState, useCallback,  } from 'react'

export function useSelection(initialSelectedRows, data) {
    const [ selectedRows, setSelectedRows ] = useState(initialSelectedRows || [])

    // Seleciona/ desseleciona item

    const selectRow = useCallback(rowId => {
        setSelectedRows(oldSelectedRows => {
            const newSelectedRows = [ ...oldSelectedRows ]
            const indexOf         = newSelectedRows.findIndex(_id => _id === rowId)

            if (indexOf !== -1) {
                newSelectedRows.splice(indexOf, 1)
            } else {
                newSelectedRows.push(rowId)
            }

            return newSelectedRows
        })
    }, [setSelectedRows])

    // Seleciona/desseleciona tudo

    const toggleRowsSelection = useCallback(() => {
        setSelectedRows(oldSelectedRows => {
            let newSelectedRows = []

            if (oldSelectedRows.length < data.length) {
                newSelectedRows = data.map(item => item.id)
            }

            return newSelectedRows
        })
    }, [ setSelectedRows, data ])

    // Limpa seleções

    const clearSelections = useCallback(() => {
        setSelectedRows([])
    }, [ setSelectedRows ])

    return {
        selectRow,
        toggleRowsSelection,
        clearSelections,
        selectedRows,
    }
}