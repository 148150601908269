import React from 'react'

import Cell from '../../common/Table/Cell'

import { Field } from 'redux-form'
import { DATA_KEY_ADMIN_USER } from '../usersConsts'

const UserAdmin = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            renderPlain={ () => (
                <div className="d-flex px-3 justify-content-center align-items-center w-100 h-100">
                    <input type="checkbox" disabled checked={!!getValue()} />
                </div>
            )}
            renderField={ () => (
               <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <Field
                        name={DATA_KEY_ADMIN_USER}
                        type="checkbox"
                        value={1}
                        component="input" />
                </ div>
            )} />
    )
}

export default UserAdmin