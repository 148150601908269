import { toast } from "react-toastify"
import { apiClient } from "../config/api"
import {
    ACTION_TYPE_SCHEDULES_FETCHING,
    ACTION_TYPE_SCHEDULES_FETCHED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE,
    ACTION_TYPE_SCHEDULES_REMOVED
} from "./schedulingActionTypes"

import isVirtualRow from '../tables/isVirtualRow'
import { logout } from "../auth/authActions"
import { tokenExpired } from "../auth/tokenExpired"

import { formatDate } from "../form/formatters"
import { parseDate } from "../form/parsers"

export function fetchSchedules(user, companyId) {
    return dispatch => {
        dispatch(fetchingSchedules(true))

        apiClient.get(`/api/accounts/${user.account_id}/companies/${companyId}/schedules`)
            .then(resp => {
                // Trata dados

                const data = resp.data.map(s => ({
                    ...s,
                    date: formatDate(s.date),
                    request_date: formatDate(s.request_date)
                }), [])

                dispatch({ type: ACTION_TYPE_SCHEDULES_FETCHED, payload: data })
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Não foi possível consultar os agendamentos')
                }
            })
            .finally(() => dispatch(fetchingSchedules(false)))
    }
}

export function fetchingSchedules(value) {
    return { type: ACTION_TYPE_SCHEDULES_FETCHING, payload: value }
}

export function saveScheduling(scheduling, user, companyId) {
    const {
        id,
        company_id,
        unit,
        request_date,
        date,
        service_type,
        technical_manager,
        status,
        file,
    } = scheduling
    const parsedDate        = parseDate(date)
    const parsedRequestDate = parseDate(request_date)

    return dispatch => {
        if (id && !isVirtualRow(id)) {
            // Atualiza

            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/schedules/${id}`,
                {
                    company_id,
                    service_type,
                    technical_manager,
                    file,
                    date: parsedDate,
                    request_date: parsedRequestDate,
                    status,
                    unit,
                    _method: 'put'
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Agendamento atualizado')
                dispatch(setEditableRow(-1))
                dispatch(fetchSchedules(user, companyId))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao atualizar agendamento')
                }
            })
        } else {
            // Cria

            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/schedules`,
                {
                    company_id,
                    service_type,
                    technical_manager,
                    file,
                    date: parsedDate,
                    request_date: parsedRequestDate,
                    status,
                    unit,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Agendamento criado')
                dispatch(setEditableRow(-1))
                dispatch(fetchSchedules(user, companyId))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao criar agendamento')
                }
            })
        }
    }
}

// Remove schedulingo

export function removeScheduling(schedulingId, user, companyId) {
    return dispatch => {
        if (!isVirtualRow(schedulingId)) {
            apiClient.delete(`/api/accounts/${user.account_id}/companies/${companyId}/schedules/${schedulingId}`)
                .then(() => {
                    toast.success('Agendamento excluído')
                    dispatch(fetchSchedules(user, companyId))
                })
                .catch(err => {
                    if (tokenExpired(err)) { // Verifica se o token expirou
                        dispatch(logout()) // Desloga usuário
                        toast.error('Sessão expirada')
                    } else {
                        toast.error('Falha ao excluir agendamento')
                    }
                })
        } else {
            dispatch({ type: ACTION_TYPE_SCHEDULES_REMOVED, payload: schedulingId })
        }
    }
}

// Remove schedulingos

export function removeSchedules(schedulesId, user, companyId) {
    return dispatch => {
        const requests = schedulesId.map(schedulingId => {
            return !isVirtualRow(schedulingId)
                ? (
                    apiClient.delete(
                        `/api/accounts/${user.account_id}/companies/${companyId}/schedules/${schedulingId}`
                    )
                )
                : (
                    dispatch({ type: ACTION_TYPE_SCHEDULES_REMOVED, payload: schedulingId })
                )
        })

        const allPromises = Promise.all(requests)

        allPromises.then(() => {
            if (schedulesId.length === 1)
                toast.success('Agendamento excluído')
            else {
                toast.success(`${schedulesId.length} agendamentos excluídos`)
            }

            dispatch(fetchSchedules(user, companyId))
        }).catch(err => {
            if (tokenExpired(err)) { // Verifica se o token expirou
                dispatch(logout()) // Desloga usuário
                toast.error('Sessão expirada')
            } else {
                if (schedulesId.length === 1) {
                    toast.error('Falha ao excluir agendamento')
                } else {
                    toast.error('Falha ao excluir agendamentos')
                }
            }
        })
    }
}

// Seta a linha que está sendo editada

export function setEditableRow(index) {
    return { type: ACTION_TYPE_TABLE_SET_ROW_EDITABLE, payload: index }
}

export function newRow() {
    return dispatch => {
        dispatch({ type: ACTION_TYPE_TABLE_NEW_ROW })
    }
}