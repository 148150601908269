import React, {
    useState,
    useMemo,
    useCallback,
    forwardRef
} from 'react'
import './TextField.css'

const TextField = forwardRef((props, ref) => {
    const { className } = props
    const [ isFocused, setIsFocused ] = useState(false)

    const _className = useMemo(() => {
        let classes = `${className || ''} TextField`

        if (isFocused)
            classes += ' focused'
        
        return classes
    }, [isFocused, className])

    const handleFocus = useCallback(() => setIsFocused(true), [setIsFocused])
    const handleBlur  = useCallback((e) => {
        if (e.target.value.length === 0)
            setIsFocused(false)
    }, [setIsFocused])

    return (
        <div className={_className}>
            { props.label && <label htmlFor={ props.input ? props.input.name : props.name}>{props.label}</label> }
            <input
                ref={ref}
                disabled={props.disabled}
                { ...props.input }
                onFocus={handleFocus}
                onBlur={handleBlur} />
        </div>
    )
})

export default TextField