import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import Cell from '../../common/Table/Cell'

import { DATA_KEY_STATUS } from '../schedulingConsts'
import { required } from '../../form/validators'

import schedulingStatus from '../schedulingStatus'
import CellSelect from '../../common/Table/CellSelect'

const SchedulingStatus = props => {
    const { getValue, row: { index }, editableRowIndex } = props
    
    const renderOption = useCallback((option) => (
        <option key={option} value={option}>{ schedulingStatus[option] }</option>
    ), [])

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={schedulingStatus[getValue()] || ''}
            renderField={ () => (
                <Field
                    name={DATA_KEY_STATUS}
                    validate={required}
                    component={CellSelect}>
                    <option value="">Selecione o status</option>
                    { Object.keys(schedulingStatus).map(renderOption) }
                </Field>
            ) } />
    )
}

SchedulingStatus.propTypes = {
    editableRowIndex: PropTypes.number,
    items: PropTypes.array,
    loading: PropTypes.bool
}

export default SchedulingStatus