export function parseDate(value) {
    if (!value)
        return value

    let dateRegExp = /^[0-9]{2}\/[0-9]{2}\/([0-9]{2}|[0-9]{4})$/

    if (dateRegExp.test(value)) {
        let [ date, month, year ] = value.split('/')

        return `${year}-${month}-${date}`
    } else {
        return value
    }
}

export function parseCnpj(value) {
    if (!value)
        return value
    
    return value.replace(/\D/g, '')
}

export function parseCurrency(value) {
    if (!value) return value

    return parseFloat(value.replace(',', '.').replace(/[R$a-z]/gi, ''))
}