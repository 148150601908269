import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import './Button.css'

const Button = props => {
    const className = useMemo(() => {
        let classes = `${props.className || ''} Button`

        switch (props.variant) {
            case 'primary':
                classes += ' btnPrimary'
                break
            case 'outline-primary':
                classes += ' btnOutlinePrimary'
                break
            case 'secondary':
                classes += ' btnSecondary'
                break
            case 'danger':
                classes += ' btnDanger'
                break
            case 'success':
                classes += ' btnSuccess'
                break
            case 'warning':
                classes += ' btnWarning'
                break
            case 'info':
                classes += ' btnInfo'
                break
            default:
                classes += ' btnMaterial'
        }

        switch (props.size) {
            case 'xs':
                classes += ' btnXs'
                break
            case 'lg':
                classes += ' btnLg'
                break
            case 'xl':
                classes += ' btnXl'
                break
            default:
                classes += ' btnMd'
        }

        switch (props.rounded) {
            case 'xs':
                classes += ' btnRoundedXs'
                break
            case 'sm':
                classes += ' btnRoundedSm'
                break
            case 'md':
                classes += ' btnRoundedMd'
                break
            case 'lg':
                classes += ' btnRoundedLg'
                break
            case 'xl':
                classes += ' btnRoundedXl'
                break
            case 'full':
                classes += ' btnRoundedFull'
                break
        }

        return classes
    }, [
            props.variant,
            props.size,
            props.className,
            props.rounded
        ])

    return (
        <button { ...props } className={className}>
            { props.children }
        </button>
    )
}

Button.propTypes = {
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'light',
        'outline-primary',
        'danger',
        'success',
        'warning',
        'info'
    ]),
    size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]),
    rounded: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl', 'full', 'none' ])
}

Button.defaultProps = {
    variant: 'primary',
    size: 'md',
    rounded: 'none'
}


export default Button