import React from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Translator from '../common/I18n/Translator'

const DataController = props => {
    const {
        onAdd,
        onRemove,
        loading,
        editableRowIndex,
        selectedRows
    } = props

    return (
        <div className="d-flex py-2 justify-content-end">
            <Button
                className="me-2" 
                type="button"
                disabled={loading}
                rounded="full"
                onClick={onAdd}>
                <FontAwesomeIcon className="me-2" icon={ faPlus } color="#fff" />
                <Translator path="tables.header_button_add" />
            </Button>

            <Button
                variant="danger"
                type="button"
                rounded="full"
                disabled={ (loading || editableRowIndex !== -1) || selectedRows.length === 0}
                onClick={onRemove }>
                <FontAwesomeIcon className="me-2" icon={ faTrashCan } color="#fff" />
                <Translator path="tables.header_button_delete" />
            </Button>
        </div>
    )
}

DataController.propTypes = {
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    loading: PropTypes.bool,
    editableRowIndex: PropTypes.number,
    selectedRows: PropTypes.array
}

export default DataController