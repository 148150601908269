import React, { useMemo } from 'react'
import './Feedback.css'

import PropTypes from 'prop-types'

const Feedback = props => {
    const { className, type } = props

    const classes = useMemo(() => `${className || ''} Feedback ${type || ''}`, [className, type])

    return (
        <div className={classes}>{props.children}</div>
    )
}

Feedback.propTypes = {
    type: PropTypes.oneOf([ 'valid', 'invalid' ])
}

Feedback.defaultProps = {
    type: ''
}

export default Feedback