import React from 'react'

import Tabs from '../common/Tabs/Tabs'
import TabList from '../common/Tabs/TabList'
import Tab from '../common/Tabs/Tab'
import TabPanel from '../common/Tabs/TabPanel'

import PageSection from '../layout/PageSection/PageSection'
import PaymentHistoryTableForm from './paymentHistoryTableForm'

const Payment = props => (
    <PageSection
        containerFluid
        title="Pagamentos"
        backButton>
        <div className="row d-flex justify-content-center">
            <div className="col-lg-12">
                <PaymentHistoryTableForm />
            </div>
        </div>
    </PageSection>
)

export default Payment