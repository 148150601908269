import {
    ACTION_TYPE_USERS_FETCHED,
    ACTION_TYPE_USERS_FETCHING,
    ACTION_TYPE_USERS_REMOVED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE
} from './usersActionTypes'

const initialValues = {
    data: [],
    loading: false,
    editableRowIndex: -1,
}

function usersReducer(state = initialValues, action) {
    switch (action.type) {
        case ACTION_TYPE_USERS_FETCHED:
            return { ...state, data: [ ...action.payload ] }
        case ACTION_TYPE_USERS_FETCHING:
            return { ...state, loading: action.payload }
        case ACTION_TYPE_USERS_REMOVED:
            const data = [ ...state.data ]

            let indexOf = data.findIndex(u => u.id === action.payload)
            if (indexOf !== -1) {
                data.splice(indexOf, 1)
            }

            return { ...state, data }
        case ACTION_TYPE_TABLE_SET_ROW_EDITABLE:
            return  { ...state, editableRowIndex: action.payload }
        case ACTION_TYPE_TABLE_NEW_ROW:
            return  {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: `ROW_${Date.now()}`,
                        name: '',
                        email: '',
                        password: '',
                        admin_user: false,
                        company_id: Date.now() // Id temporária
                    }
                ],
                editableRowIndex: state.data.length
        }
        default:
            return state
    }
}

export default usersReducer