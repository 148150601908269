import React from 'react'
import PropTypes from 'prop-types'

import Cell from '../../common/Table/Cell'
import { Field } from 'redux-form'

import { DATA_KEY_PASSWORD } from '../usersConsts'

import { required } from '../../form/validators'
import CellField from '../../common/Table/CellField'
import isVirtualRow from '../../tables/isVirtualRow'

const UserPassword = props => {
    const {
            getValue,
            row: { index, original: { id } },
            editableRowIndex
    } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={ getValue() }
            renderPlain={ ({ plainText }) => <div className="d-flex px-3 align-items-center w-100 h-100">{ plainText }</div>  }
            renderField={ () => (
                <div className="d-flex align-items-center w-100 h-100">
                    <Field
                        name={DATA_KEY_PASSWORD}
                        type="password"
                        component={CellField}
                        validate={(value) => {
                            return !isVirtualRow(id) ? undefined : required(value)
                        }} />
                </div>
            ) } />
    )
}

UserPassword.propTypes = {
    editableRowIndex: PropTypes.number
}

export default UserPassword