import {
    ACTION_TYPE_SCHEDULES_FETCHED,
    ACTION_TYPE_SCHEDULES_FETCHING,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE
} from "./schedulingActionTypes";

const initialValues = {
    data: [],
    loading: false,
    editableRowIndex: -1
}

function schedulesReducer(state = initialValues, action) {
    switch (action.type) {
        case ACTION_TYPE_SCHEDULES_FETCHED:
            return { ...state, data: [ ...action.payload ] }
        case ACTION_TYPE_SCHEDULES_FETCHING:
            return { ...state, loading: action.payload }
        case ACTION_TYPE_TABLE_NEW_ROW:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: `ROW_${Date.now()}`,
                        unit: '',
                        request_date: '',
                        date: '',
                        company_id: '',
                        service_type: '',
                        technical_manager: '',
                        status: '',
                        annex_id: null,
                    }
                ],
                editableRowIndex: state.data.length
            }
        case ACTION_TYPE_TABLE_SET_ROW_EDITABLE:
            return  { ...state, editableRowIndex: action.payload }
        default:
            return state
    }
}

export default schedulesReducer