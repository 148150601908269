import React, { useCallback, useEffect } from 'react'
import './auth.css'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { login, handshake } from './authActions'

import LoginForm from './loginForm'
import { useNavigate } from 'react-router-dom'

const Auth = props => {
    const navigate  = useNavigate()
    const { login, handshake, auth: { user, authenticating } } = props

    useEffect(() => {
        props.handshake()
    }, [handshake])

    useEffect(() => {
        if (props.auth.user)
            navigate('/')
    }, [user])

    const handleSubmit = useCallback((values) => {
        login(values)
    }, [login])

    return (
        <div className="Auth">
            <div className="container">
                <div className="row h-100 d-flex justify-content-center align-items-center">
                    <div className="col-lg-5">
                        <LoginForm
                            isAuthenticating={authenticating}
                            onSubmit={handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    handshake,
    login
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Auth)