import React, {
    useEffect, 
    useMemo,
    useCallback,
    useState,
    useRef
} from 'react'
import './payment.css'

import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchPayments,
    newRow,
    savePayment,
    removePayment,
    removePayments,
    setEditableRow,
} from './paymentActions'
import {
    fetchCompanies
} from '../companies/companiesActions'

import Table from '../common/Table/Table'

import {
    DATA_KEY_ID,
    DATA_KEY_UNIT,
    DATA_KEY_SERVICE_DATE,
    DATA_KEY_DUE_DATE,
    DATA_KEY_STATUS,
    DATA_KEY_BILLET_CODE,
    DATA_KEY_BILLET_ANNEX_ID,
    DATA_KEY_INVOICE_ANNEX_ID,
    DATA_KEY_COMPANY_ID,
    DATA_KEY_VALUE,
} from './paymentConsts'

import { useSelection } from '../hooks/useSelection'

import { asyncValidate } from './asyncValidate'
import { shouldAsyncValidate } from '../form/shouldAsyncValidate'
import { isSuper } from '../auth/isSuper'

import TableHeader from '../tables/tableHeader'

import PaymentId from './cells/paymentId'
import PaymentCompany from './cells/paymentCompany'
import PaymentDueDate from './cells/paymentDueDate'
import PaymentServiceDate from './cells/paymentServiceDate'
import PaymentUnit from './cells/paymentUnit'
import SelectionToggler from '../tables/selectionToggler'
import RowCheck from '../tables/rowCheck'
import PaymentStatus from './cells/paymentStatus'
import PaymentActions from './cells/paymentActions'
import PaymentBilletCode from './cells/paymentBilletCode'
import PaymentBilletFile from './cells/paymentBilletFile'
import PaymentInvoiceFile from './cells/paymentInvoiceFile'

import TabContent from '../common/Tabs/TabContent'
import OpenInvoice from './openInvoice'
import Tabs from '../common/Tabs/Tabs'
import Tab from '../common/Tabs/Tab'
import TabList from '../common/Tabs/TabList'
import TabPanel from '../common/Tabs/TabPanel'
import { getPendingPayments } from './getPendingPayments'
import NoInvoicePayment from './noInvoicePayment'
import Spinner from '../common/Spinner/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import PaymentValue from './cells/paymentValue'

import DeleteEntityDialog from '../tables/deleteEntityDialog'
import hasPermission from '../auth/hasPermission'

let PaymentHistoryTableForm = props => {
    const {
        payments: { data, loading, editableRowIndex },
        companies: { data:companiesData, loading:loadingCompanies },
        auth: { user },
        handleSubmit,
        fetchPayments,
        fetchCompanies,
        savePayment,
        removePayment,
        removePayments,
        newRow,
        setEditableRow,
        initialize
    } = props

    const tabRef                                  = useRef()
    const [ sorting, setSorting ]                 = useState([])
    const [ billet, setBillet ]                   = useState(null)
    const [ invoice, setInvoice ]                 = useState(null)
    const { company_id }                          = useParams()

    const columnVisibility        = useMemo(() => !isSuper(user) ? { actions: false, 'Ação': false } : {}, []) // Esconde colunas administrativas
    const pendingPayments  = useMemo(() => getPendingPayments(data), [data])

    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ productsToDelete, setProductsToDelete ] = useState([])

    const {
        selectedRows,
        clearSelections,
        toggleRowsSelection,
        selectRow
    } = useSelection([], data)

    // Exibe documentos e limpa seleções

    useEffect(() => {
        fetchPayments(user, company_id)
        fetchCompanies(user)
        clearSelections()
        setEditableRow(-1)
        setBillet(null)
        setInvoice(null)
    }, [
        user,
        company_id
    ])

    useEffect(() => {
        if (editableRowIndex !== -1)
            fetchCompanies(user)
    }, [ editableRowIndex, fetchCompanies, user ])

    useEffect(() => {
        initialize(data[editableRowIndex])
    }, [ initialize, data, editableRowIndex ])

    const openDeleteDialog = useCallback(paymentsId => {
        setShowDeleteDialog(true)
        setProductsToDelete([ ...paymentsId ])
    }, [ setProductsToDelete ])

    const closeDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
        setProductsToDelete([])
        clearSelections()
    }, [ setShowDeleteDialog, setProductsToDelete, clearSelections ])

    const handleDelete = useCallback(() => {
        setProductsToDelete(paymentsId => {
            if (paymentsId.length === 1)
                removePayment(paymentsId[0], user, company_id)
            else
                removePayments(paymentsId, user, company_id)

            setShowDeleteDialog(false)

            return []
        })
    }, [
        setProductsToDelete,
        setShowDeleteDialog,
        removePayments,
        removePayment,
        company_id,
        user
    ])

    const handleBillet = useCallback(billets => {
        setBillet(billets[0])
    }, [setBillet])

    const handleInvoice = useCallback(invoices => {
        setInvoice(invoices[0])
    }, [ setInvoice ])

    const onSubmit = useCallback(values => {
        savePayment({ ...values, billet_file: billet, invoice_file: invoice }, user, company_id)
    }, [savePayment, billet, invoice, user, company_id])

    const renderHeaderComponent = useCallback(() => (
        <TableHeader
            user={user}
            selectedRows={selectedRows}
            loading={loading}
            editableRowIndex={editableRowIndex}
            onAdd={() => {
                newRow()
                clearSelections()
                setBillet(null)
                setInvoice(null)
            } }
            onRemove={() => openDeleteDialog([ ...selectedRows ])} />
    ), [
        loading,
        selectedRows,
        clearSelections,
        removePayments,
        editableRowIndex,
        openDeleteDialog,
        setBillet,
        setInvoice,
        newRow,
        user,
        company_id
     ])

    const renderSelectionToggler = useCallback(() => (
        <SelectionToggler
            checked={ selectedRows.length > 0 && selectedRows.length === data.length }
            disabled={ editableRowIndex !== -1 }
            value={ selectedRows.length > 0 && selectedRows.length === data.length ? 'true' : 'false' }
            onChange={toggleRowsSelection} />
    ), [toggleRowsSelection, selectedRows, editableRowIndex, data])

    const renderRowCheck = useCallback(info => {
        return (
            <RowCheck
                { ...info }
                editableRowIndex={editableRowIndex}
                selectedRows={selectedRows}
                onChange={ id => selectRow(id) } />
        )
    }, [editableRowIndex, selectedRows, selectRow])

    const renderId = useCallback(info => (
        <PaymentId {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderCompany = useCallback(info => (
        <PaymentCompany
            { ...info }
            editableRowIndex={editableRowIndex}
            items={companiesData}
            loading={loadingCompanies} />
    ), [editableRowIndex, loadingCompanies, companiesData])

    const renderUnit = useCallback(info => (
        <PaymentUnit {...info} editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderServiceDate = useCallback(info => (
        <PaymentServiceDate {...info} editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])
    
    const renderDueDate = useCallback(info => (
        <PaymentDueDate { ...info } editableRowIndex={editableRowIndex} />
    ), [editableRowIndex])

    const renderStatus = useCallback(info => (
        <PaymentStatus { ...info } editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderValue  = useCallback(info => (
        <PaymentValue { ...info } editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderBilletFile = useCallback(info => (
        <PaymentBilletFile
            { ...info }
            editableRowIndex={editableRowIndex}
            onChange={handleBillet}
            file={billet} />
    ), [ editableRowIndex, handleBillet, billet ])

    const renderBilletCode = useCallback(info => (
        <PaymentBilletCode
            { ...info }
            editableRowIndex={editableRowIndex} />
    ), [ editableRowIndex ])

    const renderInvoiceFile = useCallback(info => (
        <PaymentInvoiceFile
            { ...info }
            editableRowIndex={editableRowIndex}
            onChange={handleInvoice}
            file={invoice} />
    ), [ editableRowIndex, handleInvoice, invoice ])

    const renderActions = useCallback(info => (
        <PaymentActions
            {...info}
            editableRowIndex={editableRowIndex}
            selectedRows={selectedRows}
            waiting={loadingCompanies}
            onCancel={() => {
                setEditableRow(-1)
                setBillet(null)
                setInvoice(null)
            }}
            onEdit={ index => setEditableRow(index) }
            onRemove={ id => openDeleteDialog([ id ]) } />
    ),
    [
        editableRowIndex,
        selectedRows,
        setEditableRow,
        loadingCompanies,
        removePayment,
        setBillet,
        setInvoice,
        openDeleteDialog,
        user,
        company_id
    ])

    const renderInvoice = useCallback(() => {
        if (loading) {
            return (
                <div className="invoiceSpinner w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner color="var(--primary-color)" size="4x"  />
                </div>
            )
        }

        if (pendingPayments.length > 0) {
            return (
                <OpenInvoice
                    thereAreMore={pendingPayments.length > 1}
                    data={pendingPayments[0]}
                    onDetailedInvoice={() => tabRef && tabRef.current ? tabRef.current.showTab(1) : false } />
            )
        } else {
            return (
                <NoInvoicePayment />
            )
        }
    }, [ pendingPayments, loading, tabRef ])

    const columns = useMemo(() => ([
        {
            id: 'actions',
            header: renderSelectionToggler,
            cell: renderRowCheck
        },
        {
            header: 'Id',
            accessorKey: DATA_KEY_ID,
            cell: renderId
        },
        {
            header: 'Empresa',
            accessorKey: DATA_KEY_COMPANY_ID,
            cell: renderCompany
        },
        {
            header: 'Unidade',
            accessorKey: DATA_KEY_UNIT,
            cell: renderUnit
        },
        {
            header: 'Data do Serviço',
            accessorKey: DATA_KEY_SERVICE_DATE,
            cell: renderServiceDate
        },
        {
            header: 'Data de Vencimento',
            accessorKey: DATA_KEY_DUE_DATE,
            cell: renderDueDate
        },
        {
            header: 'Valor',
            accessorKey: DATA_KEY_VALUE,
            cell: renderValue
        },
        {
            header: 'Status',
            accessorKey: DATA_KEY_STATUS,
            cell: renderStatus
        },
        {
            header: 'Boleto',
            accessorKey: DATA_KEY_BILLET_ANNEX_ID,
            cell: renderBilletFile
        },
        {
            header: 'Código do boleto',
            accessorKey: DATA_KEY_BILLET_CODE,
            cell: renderBilletCode
        },
        {
            header: 'Nota fiscal',
            accessorKey: DATA_KEY_INVOICE_ANNEX_ID,
            cell: renderInvoiceFile
        },
        {
            header: 'Ação',
            cell: renderActions
        }
    ]), [
        renderSelectionToggler,
        renderRowCheck,
        renderId,
        renderCompany,
        renderUnit,
        renderServiceDate,
        renderDueDate,
        renderValue,
        renderStatus,
        renderBilletFile,
        renderBilletCode,
        renderInvoiceFile,
        renderActions
    ])

    const getRowProps = useCallback(({index}) => ({
        className: `${index === editableRowIndex ? 'active' : ''}`
    }), [ editableRowIndex ])

    return (
        <div>
            <Tabs ref={tabRef}>
                <TabList>
                    <Tab>Em aberto</Tab>
                    <Tab badge={
                        pendingPayments.length > 1 ?
                        {
                            children: <FontAwesomeIcon icon={faInfo} size="2sm" color="#fff" />,
                            variant: 'info'
                        } : null
                    }>Histórico</Tab>
                </TabList>
                <TabContent>
                    <TabPanel>
                        { renderInvoice() }
                    </TabPanel>
                    <TabPanel>
                        
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Table
                                data={data}
                                columns={columns}
                                loading={loading}
                                state={{
                                    sorting
                                }}
                                initialState={{
                                    columnVisibility
                                }}
                                getRowProps={getRowProps}
                                headerComponent={renderHeaderComponent} />
                        </form>
                    </TabPanel>
                    <TabPanel>
                        Extrato
                    </TabPanel>
                    <TabPanel>
                        Comprovante
                    </TabPanel>
                </TabContent>
            </Tabs>
            {
                isSuper(user) ? (
                    <DeleteEntityDialog
                        entityName={productsToDelete.length > 1 ? "Pagamentos" : "Pagamento"}
                        open={showDeleteDialog}
                        onClose={closeDeleteDialog}
                        onNegative={closeDeleteDialog}
                        onPositive={handleDelete} />
                )
                : null
            }
            
        </div>
    )
}

PaymentHistoryTableForm = reduxForm({
    form: 'payment',
    asyncValidate,
    asyncChangeFields: [ 'billet_file', 'invoice_file' ],
    shouldAsyncValidate
})(PaymentHistoryTableForm)

PaymentHistoryTableForm = hasPermission(PaymentHistoryTableForm)

const mapStateToProps = state => ({
    auth: state.auth,
    payments: state.payments,
    companies: state.companies,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchPayments,
    fetchCompanies,
    removePayment,
    removePayments,
    setEditableRow,
    newRow,
    savePayment
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryTableForm)