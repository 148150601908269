import {
    ACTION_CONTRACTS_FETCHED,
    ACTION_CONTRACTS_FETCHING,
    ACTION_CONTRACT_REMOVE,
    ACTION_TABLE_NEW_ROW,
    ACTION_TABLE_SET_ROW_EDITABLE,
} from './contractsActionTypes'

const initialValues = {
    data: [],
    loading: false,
    editableRowIndex: -1,
}

function contractsReducer(state = initialValues, action) {
    switch (action.type) {
        case ACTION_CONTRACTS_FETCHED:
            return { ...state, data: [ ...action.payload ] }
        case ACTION_CONTRACTS_FETCHING:
            return { ...state, loading: action.payload }
        case ACTION_CONTRACT_REMOVE:
            const data = [ ...state.data ]

            let indexOf = data.findIndex(c => c.id === action.payload)
            if (indexOf !== -1) {
                data.splice(indexOf, 1)
            }

            return { ...state, data }
        case ACTION_TABLE_SET_ROW_EDITABLE:
            return  { ...state, editableRowIndex: action.payload }
        case ACTION_TABLE_NEW_ROW:
            return  {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: `ROW_${Date.now()}`,
                        technical_manager: '',
                        unit: '',
                        date: '',
                        annex_id: null,
                        status: 'ATIVO'
                    }
                ],
                editableRowIndex: state.data.length
        }
        default:
            return state
    }
}

export default contractsReducer