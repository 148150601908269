import React from 'react'

import PageSection from '../layout/PageSection/PageSection'
import UsersTableForm from './usersTableForm'

const Users = props => {
    return (
        <section className="Users">
            <PageSection
                containerFluid
                title="Usuários"
                backButton>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <UsersTableForm />
                    </div>
                </div>
            </PageSection>
        </section>
    )
}

export default Users