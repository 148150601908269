import React from 'react'
import PropTypes from 'prop-types'

import Cell from '../../common/Table/Cell'
import { Field } from 'redux-form'
import { required } from '../../form/validators'
import CellField from '../../common/Table/CellField'

import { DATA_KEY_SERVICE_TYPE } from '../schedulingConsts'

const SchedulingServiceType = props => {
    const { getValue, row: { index }, editableRowIndex } = props

    return (
        <Cell
            editable={ index === editableRowIndex }
            plainText={getValue()}
            renderField={ () => (
                <Field
                    name={DATA_KEY_SERVICE_TYPE}
                    validate={required}
                    component={CellField} />
            ) } />
    )
}

SchedulingServiceType.propTypes = {
    editableRowIndex: PropTypes.number,
}

export default SchedulingServiceType