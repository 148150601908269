import React from 'react'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import LogoutButton from './logoutButton'
import './userProfile.css'

const UserProfile = props => {
    const { onLogout, user } = props

    return (
        <div className="UserProfile">
            <div className="userAccount">
                <FontAwesomeIcon className="userIcon" icon={faUser} color="#000" size="2x" />
                <span className="username">{ user.name }</span>
            </div>
            <LogoutButton onClick={onLogout} />
        </div>
    )
}

UserProfile.propTypes ={
    user: PropTypes.any,
    onLogout: PropTypes.func
}

export default UserProfile