import React from 'react'

// Páginas

import Auth from '../auth/auth'
import Home from '../home/home'
import Payment from '../payment/payment'
import Contracts from '../contracts/contracts'
import Scheduling from '../scheduling/scheduling'
import Documents from '../documents/documents'
import Logout from '../auth/logout'

import { Routes, Route } from 'react-router-dom'
import WithAuth from '../auth/withAuth'
import PageNotFound from '../404/pageNotFound'
import Companies from '../companies/companies'
import Users from '../users/users'
import CompanySelector from '../companies/companySelector'
import AdminUsers from '../users/adminUsers'

const RoutesConfig = props => (
    <Routes>
        <Route path="/" index exact element={<WithAuth><Home /></WithAuth>} />
        <Route path="/auth" exact element={<Auth />} />
        <Route path="/logout" exact element={<Logout />} />
        <Route path="/servicos">
            <Route path="usuarios" element={<WithAuth _super admin><CompanySelector route="servicos/usuarios" /></WithAuth>} />
            <Route path="usuarios-admnistrativos"
                element={
                <WithAuth adminOnly>
                    <AdminUsers />
                </WithAuth>
                } />
            <Route path="empresas" element={<WithAuth _super><Companies /></WithAuth>} />
            <Route path="pagamentos" element={<WithAuth><CompanySelector route="servicos/pagamentos" /></WithAuth>} />
            <Route path="contratos" element={<WithAuth><CompanySelector route="servicos/contratos" /></WithAuth>} />
            <Route path="documentacoes" element={<WithAuth><CompanySelector route="servicos/documentacoes" /></WithAuth>} />
            <Route path="agendamentos" element={<WithAuth><CompanySelector route="servicos/agendamentos" /></WithAuth>} />
        </Route>
        <Route path=":company_id/servicos">
            <Route path="pagamentos" element={<WithAuth><Payment /></WithAuth>} />
            <Route path="usuarios" element={<WithAuth><Users /></WithAuth>} />
            <Route path="contratos" element={<WithAuth><Contracts /></WithAuth> } />
            <Route path="documentacoes" element={<WithAuth><Documents /></WithAuth>} />
            <Route path="agendamentos" element={<WithAuth><Scheduling /></WithAuth>} />
        </Route>

        <Route path="*" element={ <PageNotFound /> } />
    </Routes>
)

export default RoutesConfig