import {
    ACTION_TYPE_DOCUMENTS_FETCHED,
    ACTION_TYPE_DOCUMENTS_FETCHING,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE
} from "./documentsActionTypes";

const initialValues = {
    data: [],
    loading: false,
    editableRowIndex: -1
}

function documentsReducer(state = initialValues, action) {
    switch (action.type) {
        case ACTION_TYPE_DOCUMENTS_FETCHED:
            return { ...state, data: [ ...action.payload ] }
        case ACTION_TYPE_DOCUMENTS_FETCHING:
            return { ...state, loading: action.payload }
        case ACTION_TYPE_TABLE_NEW_ROW:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: `ROW_${Date.now()}`,
                        company_id: '',
                        name: '',
                        type: '',
                        annex_id: null,
                    }
                ],
                editableRowIndex: state.data.length
            }
        case ACTION_TYPE_TABLE_SET_ROW_EDITABLE:
            return  { ...state, editableRowIndex: action.payload }
        default:
            return state
    }
}

export default documentsReducer