export default {
    translations: {
        auth: {
            heading: 'So nice to have you at Starpro!',
            form_title: 'Please sign in below',
            email_placeholder: 'E-mail*',
            password_placeholder: 'Password*',
            forgot_password: 'I forgot my password',
            sign_in: 'Sign in',
            signing_in: 'Signing in...'
        },
        header: {
            home: "Home",
            about_starpro: "About us",
            contact_us: "Contact us",
            customers_area: "Customer's area"
        },
        services: {
            title: "Services",
            companies: "Companies",
            users: "Users",
            payments: "Payments",
            contracts: "Contracts",
            documents: "Documents",
            schedules: "Schedules",
            admin_users: "Admin Users",
            sign_out: "Sign Out",
            table_column_name: "Name",
            go_back: "Go back"
        },
        tables: {
            column_id: "Id",
            column_cnpj: "CNPJ",
            column_name: "Name",
            column_action: "Action",
            header_button_add: "Add",
            header_button_delete: "Delete"
        },
        companies: {
            company: "Company",
        },
        home: {
            message: 'Hello, World!'
        }
    }
}