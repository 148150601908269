import { toast } from "react-toastify"
import { apiClient, BASER_URL } from "../config/api"
import {
    ACTION_TYPE_DOCUMENTS_FETCHING,
    ACTION_TYPE_DOCUMENTS_FETCHED,
    ACTION_TYPE_TABLE_NEW_ROW,
    ACTION_TYPE_TABLE_SET_ROW_EDITABLE,
    ACTION_TYPE_DOCUMENTS_REMOVED
} from "./documentsActionTypes"

import { logout } from "../auth/authActions"
import { tokenExpired } from "../auth/tokenExpired"
import isVirtualRow from '../tables/isVirtualRow'

import { formatDate } from "../form/formatters"
import { parseDate } from "../form/parsers"

export function fetchDocuments(user, companyId) {
    return dispatch => {
        dispatch(fetchingDocuments(true))

        apiClient.get(`${BASER_URL}/api/accounts/${user.account_id}/companies/${companyId}/documentations`)
            .then(resp => {
                // Trata dados

                const data = resp.data.map(d => ({...d, date: formatDate(d.date) }), [])

                dispatch({ type: ACTION_TYPE_DOCUMENTS_FETCHED, payload: data })
                dispatch(fetchingDocuments(false))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Não foi possível consultar as documentações')
                }
            })
    }
}

export function fetchingDocuments(value) {
    return { type: ACTION_TYPE_DOCUMENTS_FETCHING, payload: value }
}

export function saveDocument(document, user, companyId) {
    const { id, company_id, type, name, file, date, unit } = document
    const parsedDate = parseDate(date)

    return dispatch => {
        if (id && !isVirtualRow(id)) {
            // Atualiza

            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/documentations/${id}`,
                {
                    company_id,
                    type,
                    name,
                    file,
                    date: parsedDate,
                    unit,
                    _method: 'put'
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Documento atualizado')
                dispatch(setEditableRow(-1))
                dispatch(fetchDocuments(user, companyId))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao atualizar documento')
                }
            })
        } else {
            // Cria

            apiClient.post(
                `/api/accounts/${user.account_id}/companies/${companyId}/documentations`,
                {
                    company_id,
                    type,
                    name,
                    file,
                    date:
                    parsedDate, unit
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(() => {
                toast.success('Documento criado')
                dispatch(setEditableRow(-1))
                dispatch(fetchDocuments(user, companyId))
            }).catch(err => {
                if (tokenExpired(err)) { // Verifica se o token expirou
                    dispatch(logout()) // Desloga usuário
                    toast.error('Sessão expirada')
                } else {
                    toast.error('Falha ao criar documento')
                }
            })
        }
    }
}

// Remove documento

export function removeDocument(documentId, user, companyId) {
    return dispatch => {
        if (!isVirtualRow(documentId)) {
            apiClient.delete(`/api/accounts/${user.account_id}/companies/1/documentations/${documentId}`)
                .then(() => {
                    toast.success('Documento removido')
                    dispatch(fetchDocuments(user, companyId))
                })
                .catch(err => {
                    if (tokenExpired(err)) { // Verifica se o token expirou
                        dispatch(logout()) // Desloga usuário
                        toast.error('Sessão expirada')
                    } else {
                        toast.error('Falha ao excluir documento')
                    }
                })
        } else {
            dispatch({ type: ACTION_TYPE_DOCUMENTS_REMOVED, payload: documentId })
        }
    }
}

// Remove documentos

export function removeDocuments(documentsId, user, companyId) {
    return dispatch => {
        const requests = documentsId.map(documentId => {
            return !isVirtualRow(documentId)
                ? (
                    apiClient.delete(
                        `/api/accounts/${user.account_id}/companies/1/documentations/${documentId}`
                    )
                )
                : (
                    dispatch({ type: ACTION_TYPE_DOCUMENTS_REMOVED, payload: documentId })
                )
        })

        const allPromises = Promise.all(requests)

        allPromises.then(() => {
            if (documentsId.length === 1)
                toast.success('Documento excluído')
            else {
                toast.success(`${documentsId.length} documentos excluídos`)
            }

            dispatch(fetchDocuments(user, companyId))
        }).catch(err => {
            if (tokenExpired(err)) { // Verifica se o token expirou
                dispatch(logout()) // Desloga usuário
                toast.error('Sessão expirada')
            } else {
                if (documentsId.length === 1) {
                    toast.error('Falha ao excluir documento')
                } else {
                    toast.error('Falha ao excluir documentos')
                }
            }
        })
    }
}

// Seta a linha que está sendo editada

export function setEditableRow(index) {
    return { type: ACTION_TYPE_TABLE_SET_ROW_EDITABLE, payload: index }
}

export function newRow() {
    return dispatch => {
        dispatch({ type: ACTION_TYPE_TABLE_NEW_ROW })
    }
}