import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'react-responsive-modal'
import Button from '../common/Button/Button'

const DeleteEntityDialog = props => {
    const {
        entityName,
        onPositive,
        onNegative
    } = props

    return (
        <Modal {...props} center>
            <h2 className="dialogTitle headingSm mb-3">Excluir {entityName}</h2>
            <p className="dialogText mb-3">Continuar com a operação?</p>

            <div className="d-flex justify-content-end">
                <Button className="me-3" variant="secondary" rounded="full" onClick={onNegative}>Não</Button>
                <Button variant="primary" rounded="full" onClick={onPositive}>Sim</Button>
            </div>
        </Modal>
    )
}

DeleteEntityDialog.propTypes = {
    entityName: PropTypes.string.isRequired,
    onPositive: PropTypes.func,
    onNegative: PropTypes.func
}

export default DeleteEntityDialog