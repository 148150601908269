import { useParams } from "react-router-dom"
import { isSuper } from "./isSuper"
import Unauthorized from "./unauthorized"

const hasPermission = Component => ((props) => {
    const { company_id }     = useParams()
    const { auth: { user } } = props

    if (!isSuper(user)) {
        if (`${user.company_id}` !== company_id)
            return <Unauthorized />
    }

    return <Component { ...props } />
})

export default hasPermission