import React from 'react'
import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'

import './styles/bootstrap-grid.min.css'
import './styles/bootstrap-utilities.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-responsive-modal/styles.css'

import './index.css'

import App from './App'
import storeConfig from './config/storeConfig'
import './i18n' // Traduções


const store = storeConfig()
const root = ReactDOM.createRoot(document.getElementById('root'))


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);