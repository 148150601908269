import React from 'react'
import './SideNav.css'

import PropTypes from 'prop-types'
import ButtonLink from '../../common/ButtonLink/ButtonLink'
import Divider from '../../common/Divider/Divider'
import UserProfile from '../../auth/userProfile'

import { useEffect } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../../auth/authActions'

const SideNav = props => {
    const { auth: { user }, logout } = props
    
    useEffect(() => {
        if (props.visible) {
            document.body.style.overflowY = 'hidden'
        }
        else {
            document.body.style.overflowY = 'auto'
        }
    }, [ props.visible ])

    return (
        <div className={`SideNav ${props.visible ? 'open' : ''}`}>
            <aside>
                <nav className="sideMenu">
                    {
                        user && <div className="d-flex justify-content-center mb-3"><UserProfile user={user} onLogout={logout} /></div>
                    }

                    <ButtonLink className="mb-3 ml-3 mr-3" to="#">Área do cliente</ButtonLink>
                    <Divider className="mb-3" style={{ margin: '0 10%', height: '2px' }} />
                
                    <ul className="menuItems">
                        {
                            props.children
                        }
                    </ul>
                </nav>
            </aside>
        </div>
    )
}

SideNav.propTypes = {
    visible: PropTypes.bool,
}

const mapStateToProps = state => ({
    auth: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
    logout
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)