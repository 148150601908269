import React from 'react'
import PropTypes from 'prop-types'

import RowActions from '../../tables/rowActions'

const PaymentActions = props => {
    const {
        row: { original, index },
        waiting,
        editableRowIndex,
        selectedRows,
        onEdit,
        onRemove,
        onCancel
    } = props

    const disabled = editableRowIndex === index
        ? waiting
        : (editableRowIndex !== -1 || selectedRows.includes(original.id))

    return (
        <RowActions
            edit={ editableRowIndex === index }
            disabled={disabled}
            onCancel={ onCancel }
            onEdit={ () => onEdit ? onEdit(index) : false }
            onRemove={ () => onRemove ? onRemove(original.id) : false } />
    )
}

PaymentActions.propTypes = {
    editableRowIndex: PropTypes.number,
    selectedRows: PropTypes.array,
    waiting: PropTypes.bool,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onRemove: PropTypes.func
}

export default PaymentActions