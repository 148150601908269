export default {
    translations: {
        auth: {
            heading: 'Que bom ter você na Starpro!',
            form_title: 'Faça seu login abaixo',
            email_placeholder: 'E-mail*',
            password_placeholder: 'Senha*',
            forgot_password: 'Esqueci minha senha',
            sign_in: 'Acessar',
            signing_in: 'Acessando...'
        },
        header: {
            home: "Home",
            about_starpro: "A Starpro",
            contact_us: "Contato",
            customers_area: "Área do cliente"
        },
        services: {
            title: "Serviços",
            companies: "Empresas",
            users: "Usuários",
            payments: "Pagamentos",
            contracts: "Contratos",
            documents: "Documentações",
            schedules: "Agendamentos",
            admin_users: "Usuários Administrativos",
            sign_out: "Sair",
            go_back: "Voltar"
        },
        companies: {
            company: "Empresa",
        },
        tables: {
            column_id: "Id",
            column_cnpj: "CNPJ",
            column_name: "Nome",
            column_action: "Ação",
            header_button_add: "Adicionar",
            header_button_delete: "Excluir"
        },
        home: {
            message: 'Olá Mundo!'
        }
    }
}