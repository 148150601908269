import React from 'react'
import './serviceLink.css'

import { Link } from 'react-router-dom'


import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ServiceLink = props => (
    <div className="ServiceLink">
        <Link to={props.to} className="linkIcon">
            <FontAwesomeIcon icon={props.icon} size="2x" color="#000" />
        </Link>
        <span className="linkTitle">
            { props.title }
        </span>
    </div>
)

ServiceLink.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    to: PropTypes.string
}

export default ServiceLink