import React from 'react'
import BackButton from '../common/BackButton/BackButton'
import './unauthorized.css'

const Unauthorized = props => (
    <section
        className="Unauthorized">
        <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            <h1 className="headingXl c-text text-center mb-5" >
                <span className="c-primary">Ops!</span><br />
                Você não possui permissão para acessar esta página.
            </h1>

            { props.backButton && <BackButton /> } 
        </div>
    </section>
)

export default Unauthorized