import React from 'react'
import './PageSection.css'


import Divider from '../../common/Divider/Divider'

import PropTypes from 'prop-types'
import BackButton from '../../common/BackButton/BackButton'

const PageSection = props =>  {
    const { className, titleProps, title, children, backButton, containerFluid } = props
    return (
        <div className={`${className || ''} PageSection`}>
            <div className={`container${containerFluid ? '-fluid' : ''}`}>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8">
                        <h3
                            {...titleProps} 
                            className={`${titleProps && titleProps.className ? titleProps.className : ''} pageTitle headingMd c-primary mb-3`}>
                                {title}
                        </h3>
                        <Divider className="mb-lg-5" />
                    </div>
                </div>
                <div>
                { children }
                </div>
                {
                    backButton 
                        && (
                            <div className="d-flex justify-content-center mt-5">
                                <BackButton />
                            </div>
                        ) 
                }
            </div>
        </div>
    )
}

PageSection.propTypes = {
    containerFluid: PropTypes.bool,
    title: PropTypes.string,
    titleProps: PropTypes.object,
    backButton: PropTypes.bool,
}

export default PageSection