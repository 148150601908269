import React from 'react'
import './Tab.css'
import PropTypes from 'prop-types'
import Badge from '../Badge/Badge'

const Tab = props => {
    const { badge, active } = props

    return (
        <div
            onClick={props.onClick}
            className={`Tab ${active ? 'active' : ''}`}>
            { props.children }
            { (badge && !active) &&
                <Badge className="ms-1" variant={badge.variant}>{badge.children}</ Badge> }
        </div>
    )
}

Tab.propTypes = {
    badge: PropTypes.shape({
        children: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
        variant: PropTypes.string,
    })
}

export default Tab